// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { SnapshotFrame, SnapshotFrameT } from '../fbfloorplanner/snapshot-frame.js';


export class Snapshot implements flatbuffers.IUnpackableObject<SnapshotT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Snapshot {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSnapshot(bb:flatbuffers.ByteBuffer, obj?:Snapshot):Snapshot {
  return (obj || new Snapshot()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSnapshot(bb:flatbuffers.ByteBuffer, obj?:Snapshot):Snapshot {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Snapshot()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static bufferHasIdentifier(bb:flatbuffers.ByteBuffer):boolean {
  return bb.__has_identifier('SNAP');
}

timestampSinceRef():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

title():string|null
title(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
title(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

text():string|null
text(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
text(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

frames(index: number, obj?:SnapshotFrame):SnapshotFrame|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new SnapshotFrame()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

framesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

activeFrame():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
}

static startSnapshot(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addTimestampSinceRef(builder:flatbuffers.Builder, timestampSinceRef:number) {
  builder.addFieldFloat64(0, timestampSinceRef, 0.0);
}

static addTitle(builder:flatbuffers.Builder, titleOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, titleOffset, 0);
}

static addText(builder:flatbuffers.Builder, textOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, textOffset, 0);
}

static addFrames(builder:flatbuffers.Builder, framesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, framesOffset, 0);
}

static createFramesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startFramesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addActiveFrame(builder:flatbuffers.Builder, activeFrame:number) {
  builder.addFieldInt32(4, activeFrame, 0);
}

static endSnapshot(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishSnapshotBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'SNAP');
}

static finishSizePrefixedSnapshotBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'SNAP', true);
}

static createSnapshot(builder:flatbuffers.Builder, timestampSinceRef:number, titleOffset:flatbuffers.Offset, textOffset:flatbuffers.Offset, framesOffset:flatbuffers.Offset, activeFrame:number):flatbuffers.Offset {
  Snapshot.startSnapshot(builder);
  Snapshot.addTimestampSinceRef(builder, timestampSinceRef);
  Snapshot.addTitle(builder, titleOffset);
  Snapshot.addText(builder, textOffset);
  Snapshot.addFrames(builder, framesOffset);
  Snapshot.addActiveFrame(builder, activeFrame);
  return Snapshot.endSnapshot(builder);
}

unpack(): SnapshotT {
  return new SnapshotT(
    this.timestampSinceRef(),
    this.title(),
    this.text(),
    this.bb!.createObjList<SnapshotFrame, SnapshotFrameT>(this.frames.bind(this), this.framesLength()),
    this.activeFrame()
  );
}


unpackTo(_o: SnapshotT): void {
  _o.timestampSinceRef = this.timestampSinceRef();
  _o.title = this.title();
  _o.text = this.text();
  _o.frames = this.bb!.createObjList<SnapshotFrame, SnapshotFrameT>(this.frames.bind(this), this.framesLength());
  _o.activeFrame = this.activeFrame();
}
}

export class SnapshotT implements flatbuffers.IGeneratedObject {
constructor(
  public timestampSinceRef: number = 0.0,
  public title: string|Uint8Array|null = null,
  public text: string|Uint8Array|null = null,
  public frames: (SnapshotFrameT)[] = [],
  public activeFrame: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const title = (this.title !== null ? builder.createString(this.title!) : 0);
  const text = (this.text !== null ? builder.createString(this.text!) : 0);
  const frames = Snapshot.createFramesVector(builder, builder.createObjectOffsetList(this.frames));

  return Snapshot.createSnapshot(builder,
    this.timestampSinceRef,
    title,
    text,
    frames,
    this.activeFrame
  );
}
}
