// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';



export class Matrix3x3 implements flatbuffers.IUnpackableObject<Matrix3x3T> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Matrix3x3 {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

r0c0():number {
  return this.bb!.readFloat32(this.bb_pos);
}

r1c0():number {
  return this.bb!.readFloat32(this.bb_pos + 4);
}

r2c0():number {
  return this.bb!.readFloat32(this.bb_pos + 8);
}

r0c1():number {
  return this.bb!.readFloat32(this.bb_pos + 12);
}

r1c1():number {
  return this.bb!.readFloat32(this.bb_pos + 16);
}

r2c1():number {
  return this.bb!.readFloat32(this.bb_pos + 20);
}

r0c2():number {
  return this.bb!.readFloat32(this.bb_pos + 24);
}

r1c2():number {
  return this.bb!.readFloat32(this.bb_pos + 28);
}

r2c2():number {
  return this.bb!.readFloat32(this.bb_pos + 32);
}

static sizeOf():number {
  return 36;
}

static createMatrix3x3(builder:flatbuffers.Builder, r0c0: number, r1c0: number, r2c0: number, r0c1: number, r1c1: number, r2c1: number, r0c2: number, r1c2: number, r2c2: number):flatbuffers.Offset {
  builder.prep(4, 36);
  builder.writeFloat32(r2c2);
  builder.writeFloat32(r1c2);
  builder.writeFloat32(r0c2);
  builder.writeFloat32(r2c1);
  builder.writeFloat32(r1c1);
  builder.writeFloat32(r0c1);
  builder.writeFloat32(r2c0);
  builder.writeFloat32(r1c0);
  builder.writeFloat32(r0c0);
  return builder.offset();
}


unpack(): Matrix3x3T {
  return new Matrix3x3T(
    this.r0c0(),
    this.r1c0(),
    this.r2c0(),
    this.r0c1(),
    this.r1c1(),
    this.r2c1(),
    this.r0c2(),
    this.r1c2(),
    this.r2c2()
  );
}


unpackTo(_o: Matrix3x3T): void {
  _o.r0c0 = this.r0c0();
  _o.r1c0 = this.r1c0();
  _o.r2c0 = this.r2c0();
  _o.r0c1 = this.r0c1();
  _o.r1c1 = this.r1c1();
  _o.r2c1 = this.r2c1();
  _o.r0c2 = this.r0c2();
  _o.r1c2 = this.r1c2();
  _o.r2c2 = this.r2c2();
}
}

export class Matrix3x3T implements flatbuffers.IGeneratedObject {
constructor(
  public r0c0: number = 0.0,
  public r1c0: number = 0.0,
  public r2c0: number = 0.0,
  public r0c1: number = 0.0,
  public r1c1: number = 0.0,
  public r2c1: number = 0.0,
  public r0c2: number = 0.0,
  public r1c2: number = 0.0,
  public r2c2: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Matrix3x3.createMatrix3x3(builder,
    this.r0c0,
    this.r1c0,
    this.r2c0,
    this.r0c1,
    this.r1c1,
    this.r2c1,
    this.r0c2,
    this.r1c2,
    this.r2c2
  );
}
}
