// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { DecodedFlatbufferType } from '../fbfloorplanner/decoded-flatbuffer-type.js';


export class DecodedFlatbuffer implements flatbuffers.IUnpackableObject<DecodedFlatbufferT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):DecodedFlatbuffer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsDecodedFlatbuffer(bb:flatbuffers.ByteBuffer, obj?:DecodedFlatbuffer):DecodedFlatbuffer {
  return (obj || new DecodedFlatbuffer()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsDecodedFlatbuffer(bb:flatbuffers.ByteBuffer, obj?:DecodedFlatbuffer):DecodedFlatbuffer {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new DecodedFlatbuffer()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

type():DecodedFlatbufferType {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt16(this.bb_pos + offset) : DecodedFlatbufferType.Unknown;
}

static startDecodedFlatbuffer(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addType(builder:flatbuffers.Builder, type:DecodedFlatbufferType) {
  builder.addFieldInt16(0, type, DecodedFlatbufferType.Unknown);
}

static endDecodedFlatbuffer(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createDecodedFlatbuffer(builder:flatbuffers.Builder, type:DecodedFlatbufferType):flatbuffers.Offset {
  DecodedFlatbuffer.startDecodedFlatbuffer(builder);
  DecodedFlatbuffer.addType(builder, type);
  return DecodedFlatbuffer.endDecodedFlatbuffer(builder);
}

unpack(): DecodedFlatbufferT {
  return new DecodedFlatbufferT(
    this.type()
  );
}


unpackTo(_o: DecodedFlatbufferT): void {
  _o.type = this.type();
}
}

export class DecodedFlatbufferT implements flatbuffers.IGeneratedObject {
constructor(
  public type: DecodedFlatbufferType = DecodedFlatbufferType.Unknown
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return DecodedFlatbuffer.createDecodedFlatbuffer(builder,
    this.type
  );
}
}
