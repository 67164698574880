// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum AnnotationType {
  Other = 0,
  Region = 1,
  Room = 2,
  Exterior = 3,
  Openning = 4,
  Furniture = 5,
  Attachment = 6
}
