// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Frame, FrameT } from '../fbfloorplanner/frame.js';


export class SnapshotFrame implements flatbuffers.IUnpackableObject<SnapshotFrameT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SnapshotFrame {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSnapshotFrame(bb:flatbuffers.ByteBuffer, obj?:SnapshotFrame):SnapshotFrame {
  return (obj || new SnapshotFrame()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSnapshotFrame(bb:flatbuffers.ByteBuffer, obj?:SnapshotFrame):SnapshotFrame {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SnapshotFrame()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

frame(obj?:Frame):Frame|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Frame()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSnapshotFrame(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addFrame(builder:flatbuffers.Builder, frameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, frameOffset, 0);
}

static endSnapshotFrame(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSnapshotFrame(builder:flatbuffers.Builder, frameOffset:flatbuffers.Offset):flatbuffers.Offset {
  SnapshotFrame.startSnapshotFrame(builder);
  SnapshotFrame.addFrame(builder, frameOffset);
  return SnapshotFrame.endSnapshotFrame(builder);
}

unpack(): SnapshotFrameT {
  return new SnapshotFrameT(
    (this.frame() !== null ? this.frame()!.unpack() : null)
  );
}


unpackTo(_o: SnapshotFrameT): void {
  _o.frame = (this.frame() !== null ? this.frame()!.unpack() : null);
}
}

export class SnapshotFrameT implements flatbuffers.IGeneratedObject {
constructor(
  public frame: FrameT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const frame = (this.frame !== null ? this.frame!.pack(builder) : 0);

  return SnapshotFrame.createSnapshotFrame(builder,
    frame
  );
}
}
