// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { SurfaceAction } from '../fbfloorplanner/surface-action.js';


export class SurfaceMessage implements flatbuffers.IUnpackableObject<SurfaceMessageT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SurfaceMessage {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSurfaceMessage(bb:flatbuffers.ByteBuffer, obj?:SurfaceMessage):SurfaceMessage {
  return (obj || new SurfaceMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSurfaceMessage(bb:flatbuffers.ByteBuffer, obj?:SurfaceMessage):SurfaceMessage {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SurfaceMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

action():SurfaceAction {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : SurfaceAction.SelectAnnotationPlane;
}

static startSurfaceMessage(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addAction(builder:flatbuffers.Builder, action:SurfaceAction) {
  builder.addFieldInt8(0, action, SurfaceAction.SelectAnnotationPlane);
}

static endSurfaceMessage(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSurfaceMessage(builder:flatbuffers.Builder, action:SurfaceAction):flatbuffers.Offset {
  SurfaceMessage.startSurfaceMessage(builder);
  SurfaceMessage.addAction(builder, action);
  return SurfaceMessage.endSurfaceMessage(builder);
}

unpack(): SurfaceMessageT {
  return new SurfaceMessageT(
    this.action()
  );
}


unpackTo(_o: SurfaceMessageT): void {
  _o.action = this.action();
}
}

export class SurfaceMessageT implements flatbuffers.IGeneratedObject {
constructor(
  public action: SurfaceAction = SurfaceAction.SelectAnnotationPlane
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return SurfaceMessage.createSurfaceMessage(builder,
    this.action
  );
}
}
