// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum FurnitureType {
  Other = 0,
  Sofa = 1,
  Chair = 2,
  Table = 3,
  Bed = 4,
  Desk = 5,
  Dresser = 6,
  Nightstand = 7,
  Bookshelf = 8,
  TVStand = 9
}
