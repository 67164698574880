// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { DecodedFlatbuffer, DecodedFlatbufferT } from '../fbfloorplanner/decoded-flatbuffer.js';
import { NewAnnotation, NewAnnotationT } from '../fbfloorplanner/new-annotation.js';
import { NewMeasurement, NewMeasurementT } from '../fbfloorplanner/new-measurement.js';
import { OutgoingMessage, unionToOutgoingMessage, unionListToOutgoingMessage } from '../fbfloorplanner/outgoing-message.js';


export class FromCpp implements flatbuffers.IUnpackableObject<FromCppT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FromCpp {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFromCpp(bb:flatbuffers.ByteBuffer, obj?:FromCpp):FromCpp {
  return (obj || new FromCpp()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFromCpp(bb:flatbuffers.ByteBuffer, obj?:FromCpp):FromCpp {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FromCpp()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static bufferHasIdentifier(bb:flatbuffers.ByteBuffer):boolean {
  return bb.__has_identifier('FRCP');
}

messageType():OutgoingMessage {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : OutgoingMessage.NONE;
}

message<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

static startFromCpp(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addMessageType(builder:flatbuffers.Builder, messageType:OutgoingMessage) {
  builder.addFieldInt8(0, messageType, OutgoingMessage.NONE);
}

static addMessage(builder:flatbuffers.Builder, messageOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, messageOffset, 0);
}

static endFromCpp(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishFromCppBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'FRCP');
}

static finishSizePrefixedFromCppBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'FRCP', true);
}

static createFromCpp(builder:flatbuffers.Builder, messageType:OutgoingMessage, messageOffset:flatbuffers.Offset):flatbuffers.Offset {
  FromCpp.startFromCpp(builder);
  FromCpp.addMessageType(builder, messageType);
  FromCpp.addMessage(builder, messageOffset);
  return FromCpp.endFromCpp(builder);
}

unpack(): FromCppT {
  return new FromCppT(
    this.messageType(),
    (() => {
      const temp = unionToOutgoingMessage(this.messageType(), this.message.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })()
  );
}


unpackTo(_o: FromCppT): void {
  _o.messageType = this.messageType();
  _o.message = (() => {
      const temp = unionToOutgoingMessage(this.messageType(), this.message.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
}
}

export class FromCppT implements flatbuffers.IGeneratedObject {
constructor(
  public messageType: OutgoingMessage = OutgoingMessage.NONE,
  public message: DecodedFlatbufferT|NewAnnotationT|NewMeasurementT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const message = builder.createObjectOffset(this.message);

  return FromCpp.createFromCpp(builder,
    this.messageType,
    message
  );
}
}
