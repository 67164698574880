// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Frame, FrameT } from '../fbfloorplanner/frame.js';


export class HighlightFrame implements flatbuffers.IUnpackableObject<HighlightFrameT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):HighlightFrame {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsHighlightFrame(bb:flatbuffers.ByteBuffer, obj?:HighlightFrame):HighlightFrame {
  return (obj || new HighlightFrame()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsHighlightFrame(bb:flatbuffers.ByteBuffer, obj?:HighlightFrame):HighlightFrame {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new HighlightFrame()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

frameIndex():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

frame(obj?:Frame):Frame|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Frame()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startHighlightFrame(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addFrameIndex(builder:flatbuffers.Builder, frameIndex:number) {
  builder.addFieldInt16(0, frameIndex, 0);
}

static addFrame(builder:flatbuffers.Builder, frameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, frameOffset, 0);
}

static endHighlightFrame(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): HighlightFrameT {
  return new HighlightFrameT(
    this.frameIndex(),
    (this.frame() !== null ? this.frame()!.unpack() : null)
  );
}


unpackTo(_o: HighlightFrameT): void {
  _o.frameIndex = this.frameIndex();
  _o.frame = (this.frame() !== null ? this.frame()!.unpack() : null);
}
}

export class HighlightFrameT implements flatbuffers.IGeneratedObject {
constructor(
  public frameIndex: number = 0,
  public frame: FrameT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const frame = (this.frame !== null ? this.frame!.pack(builder) : 0);

  HighlightFrame.startHighlightFrame(builder);
  HighlightFrame.addFrameIndex(builder, this.frameIndex);
  HighlightFrame.addFrame(builder, frame);

  return HighlightFrame.endHighlightFrame(builder);
}
}
