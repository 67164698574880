// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { TRXform, TRXformT } from '../fbmath/trxform.js';


export class ModelSlice implements flatbuffers.IUnpackableObject<ModelSliceT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ModelSlice {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsModelSlice(bb:flatbuffers.ByteBuffer, obj?:ModelSlice):ModelSlice {
  return (obj || new ModelSlice()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsModelSlice(bb:flatbuffers.ByteBuffer, obj?:ModelSlice):ModelSlice {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ModelSlice()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

xform(obj?:TRXform):TRXform|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new TRXform()).__init(this.bb_pos + offset, this.bb!) : null;
}

loopVertexCounts(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

loopVertexCountsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

loopVertexCountsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

vertices(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

verticesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

verticesArray():Float32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Float32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

zMin():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

zMax():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

perimeter():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

area():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

static startModelSlice(builder:flatbuffers.Builder) {
  builder.startObject(7);
}

static addXform(builder:flatbuffers.Builder, xformOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, xformOffset, 0);
}

static addLoopVertexCounts(builder:flatbuffers.Builder, loopVertexCountsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, loopVertexCountsOffset, 0);
}

static createLoopVertexCountsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createLoopVertexCountsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createLoopVertexCountsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startLoopVertexCountsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addVertices(builder:flatbuffers.Builder, verticesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, verticesOffset, 0);
}

static createVerticesVector(builder:flatbuffers.Builder, data:number[]|Float32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createVerticesVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createVerticesVector(builder:flatbuffers.Builder, data:number[]|Float32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat32(data[i]!);
  }
  return builder.endVector();
}

static startVerticesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addZMin(builder:flatbuffers.Builder, zMin:number) {
  builder.addFieldFloat32(3, zMin, 0.0);
}

static addZMax(builder:flatbuffers.Builder, zMax:number) {
  builder.addFieldFloat32(4, zMax, 0.0);
}

static addPerimeter(builder:flatbuffers.Builder, perimeter:number) {
  builder.addFieldFloat32(5, perimeter, 0.0);
}

static addArea(builder:flatbuffers.Builder, area:number) {
  builder.addFieldFloat32(6, area, 0.0);
}

static endModelSlice(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createModelSlice(builder:flatbuffers.Builder, xformOffset:flatbuffers.Offset, loopVertexCountsOffset:flatbuffers.Offset, verticesOffset:flatbuffers.Offset, zMin:number, zMax:number, perimeter:number, area:number):flatbuffers.Offset {
  ModelSlice.startModelSlice(builder);
  ModelSlice.addXform(builder, xformOffset);
  ModelSlice.addLoopVertexCounts(builder, loopVertexCountsOffset);
  ModelSlice.addVertices(builder, verticesOffset);
  ModelSlice.addZMin(builder, zMin);
  ModelSlice.addZMax(builder, zMax);
  ModelSlice.addPerimeter(builder, perimeter);
  ModelSlice.addArea(builder, area);
  return ModelSlice.endModelSlice(builder);
}

unpack(): ModelSliceT {
  return new ModelSliceT(
    (this.xform() !== null ? this.xform()!.unpack() : null),
    this.bb!.createScalarList<number>(this.loopVertexCounts.bind(this), this.loopVertexCountsLength()),
    this.bb!.createScalarList<number>(this.vertices.bind(this), this.verticesLength()),
    this.zMin(),
    this.zMax(),
    this.perimeter(),
    this.area()
  );
}


unpackTo(_o: ModelSliceT): void {
  _o.xform = (this.xform() !== null ? this.xform()!.unpack() : null);
  _o.loopVertexCounts = this.bb!.createScalarList<number>(this.loopVertexCounts.bind(this), this.loopVertexCountsLength());
  _o.vertices = this.bb!.createScalarList<number>(this.vertices.bind(this), this.verticesLength());
  _o.zMin = this.zMin();
  _o.zMax = this.zMax();
  _o.perimeter = this.perimeter();
  _o.area = this.area();
}
}

export class ModelSliceT implements flatbuffers.IGeneratedObject {
constructor(
  public xform: TRXformT|null = null,
  public loopVertexCounts: (number)[] = [],
  public vertices: (number)[] = [],
  public zMin: number = 0.0,
  public zMax: number = 0.0,
  public perimeter: number = 0.0,
  public area: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const loopVertexCounts = ModelSlice.createLoopVertexCountsVector(builder, this.loopVertexCounts);
  const vertices = ModelSlice.createVerticesVector(builder, this.vertices);

  return ModelSlice.createModelSlice(builder,
    (this.xform !== null ? this.xform!.pack(builder) : 0),
    loopVertexCounts,
    vertices,
    this.zMin,
    this.zMax,
    this.perimeter,
    this.area
  );
}
}
