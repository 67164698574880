// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Quaternion, QuaternionT } from '../fbmath/quaternion.js';
import { Vector3, Vector3T } from '../fbmath/vector3.js';


export class TRXform implements flatbuffers.IUnpackableObject<TRXformT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TRXform {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

translation(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos, this.bb!);
}

orientation(obj?:Quaternion):Quaternion|null {
  return (obj || new Quaternion()).__init(this.bb_pos + 12, this.bb!);
}

static sizeOf():number {
  return 28;
}

static createTRXform(builder:flatbuffers.Builder, translation_x: number, translation_y: number, translation_z: number, orientation_x: number, orientation_y: number, orientation_z: number, orientation_w: number):flatbuffers.Offset {
  builder.prep(4, 28);
  builder.prep(4, 16);
  builder.writeFloat32(orientation_w);
  builder.writeFloat32(orientation_z);
  builder.writeFloat32(orientation_y);
  builder.writeFloat32(orientation_x);
  builder.prep(4, 12);
  builder.writeFloat32(translation_z);
  builder.writeFloat32(translation_y);
  builder.writeFloat32(translation_x);
  return builder.offset();
}


unpack(): TRXformT {
  return new TRXformT(
    (this.translation() !== null ? this.translation()!.unpack() : null),
    (this.orientation() !== null ? this.orientation()!.unpack() : null)
  );
}


unpackTo(_o: TRXformT): void {
  _o.translation = (this.translation() !== null ? this.translation()!.unpack() : null);
  _o.orientation = (this.orientation() !== null ? this.orientation()!.unpack() : null);
}
}

export class TRXformT implements flatbuffers.IGeneratedObject {
constructor(
  public translation: Vector3T|null = null,
  public orientation: QuaternionT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return TRXform.createTRXform(builder,
    (this.translation?.x ?? 0),
    (this.translation?.y ?? 0),
    (this.translation?.z ?? 0),
    (this.orientation?.x ?? 0),
    (this.orientation?.y ?? 0),
    (this.orientation?.z ?? 0),
    (this.orientation?.w ?? 0)
  );
}
}
