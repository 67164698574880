// ImageSpace main entry point.
import { firebaseApp } from './firebase/_app';
import { setupAuth } from './firebase/_auth';
import { Tooltip, Toast, Popover, Carousel, Modal } from 'bootstrap';
import { firebaseStorage } from './firebase/_storage'
import { User } from 'firebase/auth'
import { getStorage, ref, getBytes, getDownloadURL, list } from "firebase/storage"
import { collection, doc, getDoc, setDoc, DocumentSnapshot } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { firebaseDb } from './firebase/_database'
import { transition, isMobile, setText, hideModal, isLocalHost, isLog, showError } from './_utils'
import { Scan, ScanT } from 'src/flatbuffers/fbfloorplanner/scan'
import { Snapshot  } from './flatbuffers/fbfloorplanner/snapshot'
import { SnapshotFrame } from './flatbuffers/fbfloorplanner/snapshot-frame'
import { ToCpp, ToCppT } from 'src/flatbuffers/fbfloorplanner/to-cpp'
import { FromCpp, FromCppT } from 'src/flatbuffers/fbfloorplanner/from-cpp'
import { SurfaceAction } from 'src/flatbuffers/fbfloorplanner/surface-action'
import { SurfaceMessageT } from 'src/flatbuffers/fbfloorplanner/surface-message'
import { ViewportChangeT } from 'src/flatbuffers/fbfloorplanner/viewport-change'
import { Viewport } from 'src/flatbuffers/fbfloorplanner/viewport'
import { Perspective } from 'src/flatbuffers/fbfloorplanner/perspective'
import { Model } from 'src/flatbuffers/fbfloorplanner/model'
import * as flatbuffers from 'flatbuffers';
import { FirebaseError } from 'firebase/app'

import { IncomingMessage } from 'src/flatbuffers/fbfloorplanner/incoming-message'
import { OutgoingMessage } from 'src/flatbuffers/fbfloorplanner/outgoing-message'
import { NewAnnotationT } from 'src/flatbuffers/fbfloorplanner/new-annotation'
import { NewMeasurementT } from 'src/flatbuffers/fbfloorplanner/new-measurement'

import { PendingTexture, Viewer } from './_nativeView'
import { Main, switchTo } from './_states'
import { user, addUserChangeHandler } from './firebase/_auth'

export function loadSnapshotsFor(scan: string) {
    var scanRef = ref(firebaseStorage, `scans/${scan}/Snapshots`);


    (document.getElementById('scanLink') as HTMLLinkElement).href = `/viewer.html?s=${scan}`;

    // Evaluate the sub directories.
    list(scanRef).then((snapshot) => {
        if (snapshot.prefixes.length > 0) {
            (document.getElementById('snapshotsList') as HTMLDivElement).innerHTML = snapshot.prefixes.map((item) => {
                return `<div class="col-md-3 col-sm-6">${snapshotCard(scan, item.name, true)}</div>`;
            }).join('');

            snapshot.prefixes.forEach((item) => {
                addSnapshot(scan, item.name);
            });

            (document.getElementById('snapshotsCount') as HTMLSpanElement).innerHTML = snapshot.prefixes.length.toString();
        } else {
            (document.getElementById('snapshotsList') as HTMLDivElement).innerHTML = '<div class="alert alert-warning" role="alert">No snapshots found for this scan. You can collect snapshots during scanning by holding the <i class="bi bi-camera"></i> camera icon.</div>';
            (document.getElementById('snapshotsCount') as HTMLSpanElement).classList.add('d-none');
        }
    });
}

// Helper function to multiply two quaternions
function multiplyQuaternions(a: { x: number, y: number, z: number, w: number }, b: { x: number, y: number, z: number, w: number }) {
    return {
        w: a.w * b.w - a.x * b.x - a.y * b.y - a.z * b.z,
        x: a.w * b.x + a.x * b.w + a.y * b.z - a.z * b.y,
        y: a.w * b.y - a.x * b.z + a.y * b.w + a.z * b.x,
        z: a.w * b.z + a.x * b.y - a.y * b.x + a.z * b.w
    };
}

// Quaternion rotation function using conjugate
function rotateVectorByQuaternion(vector: number[], qx: number, qy: number, qz: number, qw: number) {
    // Quaternion conjugate
    const conj = { x: -qx, y: -qy, z: -qz, w: qw };

    // Convert vector to quaternion form (w = 0)
    const vecQuat = { x: vector[0], y: vector[1], z: vector[2], w: 0 };

    // Quaternion multiplication: q * v
    const qv = multiplyQuaternions(conj, vecQuat);

    // Quaternion multiplication: (q * v) * q_conj
    const result = multiplyQuaternions(qv, { x: qx, y: qy, z: qz, w: qw });

    // Return only the vector part
    return [result.x, result.y, result.z];
}

// Cache handling functions
async function cacheImage(key: string, blob: Blob) {
    try {
        const cache = await caches.open('rotated-images-cache');
        await cache.put(key, new Response(blob));
    } catch (error) {
        console.error('Error caching image:', error);
    }
}

async function getCachedImage(key: string) : Promise<Blob | null> {
    try {
        const cache = await caches.open('rotated-images-cache');
        const response = await cache.match(key);
        if (response) {
            return await response.blob();
        }
        return null;
    } catch (error) {
        console.error('Error retrieving cached image:', error);
        return null;
    }
}

export async function showSnapshotImage(scan: string, snapshot: string, snapshotFrame: SnapshotFrame, downsample: number = 4) {

    // The rotation to apply.
    var angle90 = 0.0;
    var swapResolution = false;

    // Figure out the rotation for the image.
    var rotation = snapshotFrame.frame()?.camera()?.xform()?.orientation();
    if (rotation) {
        var qx = rotation.x();
        var qy = rotation.y();
        var qz = rotation.z();
        var qw = rotation.w();

        const vector = [0, 0, 1];
        const rotatedVector = rotateVectorByQuaternion(vector, qx, qy, qz, qw);
        const angle = Math.atan2(rotatedVector[1], rotatedVector[0]) * 180 / Math.PI;

        // Figure out the nearest 90 degree rotation.
        if ((Math.abs(angle) < 45.0) || (Math.abs(angle) > 135.0)) {
            angle90 = (Math.abs(angle) < 45.0) ? -90.0 : 90.0;
            swapResolution = true;
        } else {
            angle90 = (angle < 0) ? 180.0 : 0.0;
        }
    }

    const fileName = downsample > 1 ? `color_0_${downsample}.webp` : `color_0.webp`;
    const cacheKey = `${scan}-${snapshot}-${fileName}-${angle90}`;

    // Try to get the cached image first
    const cachedImage = await getCachedImage(cacheKey);
    const imgElement = document.getElementById(`snapshot_${snapshot}`) as HTMLImageElement;
    if (cachedImage) {
        isLog("Using cached image");
        imgElement.src = URL.createObjectURL(cachedImage);
    } else {
        // If not cached, load and process the image
        getDownloadURL(ref(firebaseStorage, `scans/${scan}/Snapshots/${snapshot}/${fileName}`)).then((url) => {
            var image = new Image();
            image.crossOrigin = "anonymous";
            image.src = url;
            image.onload = async () => {
                const canvas = document.getElementById('canvas') as HTMLCanvasElement;
                canvas.width = swapResolution ? image.height : image.width;
                canvas.height = swapResolution ? image.width : image.height;

                const ctx = canvas.getContext("2d");
                if (ctx) {
                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    ctx.rotate(angle90 * Math.PI / 180);
                    ctx.drawImage(image, -image.width / 2, -image.height / 2);

                    // Cache the rotated image
                    canvas.toBlob(async (blob) => {
                        if (blob) {
                            await cacheImage(cacheKey, blob);
                            imgElement.src = URL.createObjectURL(blob);
                        }
                    }, 'image/webp', 0.9);
                }
            };
        });
    }
}

export function snapshotCard(scan: string, snapshot: string, thumbnail: boolean) : string {
    var buttonsHtml = thumbnail ? 
    `<a href="/snapshot.html?s=${scan}&t=${snapshot}" class="stretched-link"><i class="bi bi-file-image"></i> Open</a>` : 
    `<a href="/snapshots.html?s=${scan}"><i class="bi bi-grid"></i> Snapshots</a>
    <a href="/viewer.html?s=${scan}"><i class="bi bi-badge-3d"></i> Scan</a>`;
    var html = `<div id="card_${snapshot}" class="card mb-4">
                    <img id="snapshot_${snapshot}" src="#" alt="Snapshot" class="img-fluid" crossorigin="anonymous" alt="Snapshot thumbnail">
                    <div class="card-body">
                        <h5 id="title_${snapshot}" class="card-title"></h5>
                        <p id="text_${snapshot}" class="card-text"></p>
                        ${buttonsHtml}
                    </div>
                </div>`;

    return html;
}

export function addSnapshot(scan: string, snapshot: string) {
    var card = document.getElementById(`card_${snapshot}`) as HTMLDivElement;
    

    getBytes(ref(firebaseStorage, `scans/${scan}/Snapshots/${snapshot}/snapshot.fb`)).then((data) => {
        var s = Snapshot.getRootAsSnapshot(new flatbuffers.ByteBuffer(new Uint8Array(data)));

        // Figure out the rotation for the thumbnail.
        var f = s.frames(0);
        if (f) {    
            showSnapshotImage(scan, snapshot, f);
        }

        (document.getElementById(`title_${snapshot}`) as HTMLElement).innerHTML = s.title() ?? "Untitled";
        (document.getElementById(`text_${snapshot}`) as HTMLElement).innerHTML = s.text() ?? "";
    });
}