// ImageSpace main entry point.
import { setupAuth } from './firebase/_auth';
import { setupParameters } from './parameters';

document.addEventListener('DOMContentLoaded', function () {
    // Select all rows you want to observe
    const sections = document.querySelectorAll('.row');
    
    // Create an IntersectionObserver instance
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('highlight');
            } else {
                entry.target.classList.remove('highlight');
            }
        });
    }, { threshold: 0.5 });

    // Observe each section
    sections.forEach(section => {
        observer.observe(section);
    });
});

// // Setup the viewer.
setupParameters();

// Setup the authentication.
setupAuth();


//console.log("Ready!");


