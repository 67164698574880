import { Modal } from 'bootstrap';
import { Viewer } from './_nativeView';
import { LevelT } from './flatbuffers/fbfloorplanner/level'
import { ToCppT } from './flatbuffers/fbfloorplanner/to-cpp'
import { IncomingMessage } from './flatbuffers/fbfloorplanner/incoming-message'
import { OutgoingMessage } from './flatbuffers/fbfloorplanner/outgoing-message'
import { MeasurementMessageT } from './flatbuffers/fbfloorplanner/measurement-message'
import { MeasurementAction } from './flatbuffers/fbfloorplanner/measurement-action'
import { AnnotationMessageT } from './flatbuffers/fbfloorplanner/annotation-message'
import { AnnotationAction } from './flatbuffers/fbfloorplanner/annotation-action'
import { NewMeasurementT } from './flatbuffers/fbfloorplanner/new-measurement'
import { NewAnnotationT } from './flatbuffers/fbfloorplanner/new-annotation'
import { AnnotationSemanticsT } from './flatbuffers/fbfloorplanner/annotation-semantics'
import { isLog } from './_utils';
import { AnnotationType } from './flatbuffers/fbfloorplanner/annotation-type';
import { fbfloorplanner } from './flatbuffers/alignment';
import { AnnotationT } from './flatbuffers/fbfloorplanner/annotation';
import { RoomType } from './flatbuffers/fbfloorplanner/room-type';
import { RegionType } from './flatbuffers/fbfloorplanner/region-type';
import { ExteriorType } from './flatbuffers/fbfloorplanner/exterior-type';
import { OpenningType } from './flatbuffers/fbfloorplanner/openning-type';
import { AttachmentType } from './flatbuffers/fbfloorplanner/attachment-type';
import { FurnitureType } from './flatbuffers/fbfloorplanner/furniture-type';
class MyModal {
    viewer: Viewer;
    element: HTMLElement;
    bsModal: Modal;

    constructor(id: string, viewer: Viewer) {
        const element = document.getElementById(id);
        if (!element) {
            console.error("Modal with id", id, "not found");
            throw new Error(`Modal element with id ${id} not found`);
        }
        this.bsModal = new Modal(element);
        this.element = element;
        this.viewer = viewer;
        this.bsModal.show();
        this.viewer.nativeViewer.setHandleKeyboardEvents(false);
        element.addEventListener('hidden.bs.modal', () => {
            this.viewer.nativeViewer.setHandleKeyboardEvents(true);
        });
    }
}

export class FormModal extends MyModal {
    inputField: string;
    canSubmit: boolean = false;

    constructor(inputField: string, id: string, viewer: Viewer) {
        super(id, viewer);
        this.inputField = inputField;

        // Find the form under this.element and install a submit handler
        const form = this.element.querySelector('form') as HTMLFormElement;
        if (form) {
            form.onsubmit = (event) => {
                event.preventDefault();
                this.didSubmit(form);
                this.bsModal.hide();
            };
        } else {
            console.error("Form not found in", id);
        }

        const input = document.getElementById(inputField) as HTMLInputElement;
        if (input) {
            const submitButton = this.element.querySelector('button[type="submit"]') as HTMLButtonElement;
            if (submitButton) {

                input.oninput = () => {
                    submitButton.disabled = (input.value.trim() === '') && !this.canSubmit;
                }

                submitButton.disabled = (input.value.trim() === '') && !this.canSubmit;

            } else {
                console.error("Submit button not found", inputField);
            }
        } else {
            console.error("Input field not found", inputField);
        }
    }

    didSubmit(form: HTMLFormElement) {
    }

    syncSubmitButton() {
        const input = document.getElementById(this.inputField) as HTMLInputElement;
        if (input) {
            const submitButton = this.element.querySelector('button[type="submit"]') as HTMLButtonElement;
            if (submitButton) {
                submitButton.disabled = (input.value.trim() === '') && !this.canSubmit;
            } else {
                console.error("Submit button not found", this.inputField);
            }
        } else {
            console.error("Input field not found", this.inputField);
        }
    }
}

export class HelpModal extends MyModal {
    constructor(viewer: Viewer) {
        super("helpModal", viewer);
    }
}

export class SettingsModal extends MyModal {
    constructor(viewer: Viewer) {
        super("settingsModal", viewer);
    }
}

export class AnnotationModal extends MyModal {
    constructor(id: string, viewer: Viewer) {
        super(id, viewer);
    }

    refreshList() {
    }

    async updateAndRefresh() {
        await this.viewer.updatedAnnotations();
        this.refreshList();
    }
}

export class MeasurementsModal extends AnnotationModal {
    constructor(viewer: Viewer) {
        super("measurementsModal", viewer);
        this.refreshList();
    }

    override refreshList() {
        var measurementsList = document.getElementById('measurementsList');
        if (measurementsList) {
            if (this.viewer.annotations.measurements.length == 0) {
                measurementsList.innerHTML = '<tr><td colspan="6" class="text-center alert alert-secondary" role="alert">No measurements were created</td></tr>';
            } else {
                var listHtml= '';
                for (let i = 0; i < this.viewer.annotations.measurements.length; i++) {
                    var measurement = this.viewer.annotations.measurements[i];
                    listHtml += this.viewer.nativeViewer.metricsFor(true,false, i);
                }
                measurementsList.innerHTML = listHtml;

                for (let i = 0; i < this.viewer.annotations.measurements.length; i++) {
                    (document.getElementById(`deleteMeasurementButton${i}`) as HTMLButtonElement).onclick = () => {
                        this.viewer.annotations.measurements.splice(i, 1);
                        this.updateAndRefresh();
                    }
                }
            }
        }
    }
}


export class AnnotationsModal extends AnnotationModal {
    constructor(viewer: Viewer) {
        super("annotationsModal", viewer);
        this.refreshList();
    }

    override refreshList() {
        var annotationsList = document.getElementById('annotationsList');

        if (annotationsList) {
            if (this.viewer.annotations.annotations.length == 0) {
                annotationsList.innerHTML = '<tr><td colspan="6" class="text-center alert alert-secondary" role="alert">No annotations were created</td></tr>';
            } else {
                var listHtml = '';
                for (let i = 0; i < this.viewer.annotations.annotations.length; i++) {
                    listHtml += this.viewer.nativeViewer.metricsFor(true,true, i);
                }   
                annotationsList.innerHTML = listHtml;

                for (let i = 0; i < this.viewer.annotations.annotations.length; i++) {
                    (document.getElementById(`deleteAnnotationButton${i}`) as HTMLButtonElement).onclick = () => {
                        this.viewer.annotations.annotations.splice(i, 1);
                        this.updateAndRefresh();
                    }
                }
            }
        }
    }
}


export class LevelsModal extends AnnotationModal {
    constructor(viewer: Viewer) {
        super("levelsModal", viewer);
        this.refreshList();
    }

    override refreshList() {
        var levelsList = document.getElementById('levelsList');

        if (levelsList) {
            if (this.viewer.annotations.levels.length == 0) {
                levelsList.innerHTML = '<tr><td colspan="5" class="text-center alert alert-secondary" role="alert">No levels were created</td></tr>';
            } else {
                var listHtml = '';

                for (let i = 0; i < this.viewer.annotations.levels.length; i++) {
                    var level = this.viewer.annotations.levels[i];
                    listHtml += `<tr><td><a id="selectLevelButton${i}" href="#">${level.title}</a></td><td>${this.viewer.nativeViewer.stringForLength(level.top - level.bottom)}</td><td>${level.exportable ? '<i class="bi bi-check"></i>' : ''}</td><td>${level.aboveGrade ? '<i class="bi bi-check"></i>' : ''}</td><td><button id="deleteLevelButton${i}" class="btn" data-index="${i}" type="button"><i class="bi bi-x-circle-fill"></i></button></td></tr>`;
                }

                levelsList.innerHTML = listHtml;

                for (let i=0;i < this.viewer.annotations.levels.length;i++) { 
                    (document.getElementById(`deleteLevelButton${i}`) as HTMLButtonElement).onclick = () => {
                        this.viewer.annotations.levels.splice(i, 1);
                        this.updateAndRefresh();
                    }

                    (document.getElementById(`selectLevelButton${i}`) as HTMLLinkElement).onclick =() => {
                        return () => {
                            var level = this.viewer.annotations.levels[i];
                            this.viewer.levelSlider.set([level.bottom, level.top]);
                            this.bsModal.hide();
                        };
                    }
                }
            }
        }
    }
}




export class NewMeasurementModal extends FormModal {
    constructor(viewer: Viewer) {
        super('measurementTitle', "newMeasurementModal", viewer);
    }

    didSubmit(form: HTMLFormElement): void {
        super.didSubmit(form);

        var title = (document.getElementById('measurementTitle') as HTMLInputElement).value;

        var toCpp = new ToCppT();
        toCpp.messageType = IncomingMessage.MeasurementMessage;
        toCpp.message = new MeasurementMessageT();
        toCpp.message.action = MeasurementAction.SaveMeasurement;

        var fromCpp = this.viewer.dispatch(toCpp);
        if (fromCpp.messageType == OutgoingMessage.NewMeasurement) {
            var newMeasurement = (fromCpp.message as NewMeasurementT).measurement;
            if (newMeasurement) {
                newMeasurement.title = title;
                this.viewer.annotations.measurements.push(newMeasurement);
                this.viewer.updatedAnnotations();
            }
        }
        
        (document.getElementById('measurementTitle') as HTMLInputElement).value = '';
    }
}

export class NewAnnotationModal extends FormModal {
    newAnnotation = new AnnotationT();
    semantics = new AnnotationSemanticsT();

    constructor(viewer: Viewer) {
        super("annotationTitle", "newAnnotationModal", viewer);

        var toCpp = new ToCppT();
        toCpp.messageType = IncomingMessage.AnnotationMessage;
        toCpp.message = new AnnotationMessageT();
        toCpp.message.action = AnnotationAction.SaveAnnotation;
        
        var fromCpp = this.viewer.dispatch(toCpp);
        if (fromCpp.messageType == OutgoingMessage.NewAnnotation) {
            var annotation = (fromCpp.message as NewAnnotationT).annotation;
            if (annotation) {
                this.newAnnotation = annotation;
                this.newAnnotation.semantics = this.semantics;
                this.syncSemantics();
            }
        }

        (document.getElementById('annotationTypeSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.semantics.type = parseInt((event.target as HTMLSelectElement).value) as AnnotationType;
            this.syncSemantics();
        }

        (document.getElementById('roomTypeSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.semantics.roomType = parseInt((event.target as HTMLSelectElement).value) as RoomType;
            this.syncSemantics();
        }

        (document.getElementById('regionTypeSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.semantics.regionType = parseInt((event.target as HTMLSelectElement).value) as RegionType;
            this.syncSemantics();
        }

        (document.getElementById('exteriorTypeSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.semantics.exteriorType = parseInt((event.target as HTMLSelectElement).value) as ExteriorType;
            this.syncSemantics();
        }

        (document.getElementById('openningTypeSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.semantics.openningType = parseInt((event.target as HTMLSelectElement).value) as OpenningType;
            this.syncSemantics();
        }

        (document.getElementById('attachmentTypeSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.semantics.attachmentType = parseInt((event.target as HTMLSelectElement).value) as AttachmentType;
            this.syncSemantics();
        }

        (document.getElementById('furnitureTypeSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.semantics.furnitureType = parseInt((event.target as HTMLSelectElement).value) as FurnitureType;
            this.syncSemantics();
        }
    }

    syncSemantics() {
        if (this.semantics.type == AnnotationType.Room) {
            (document.getElementById('roomTypeRow') as HTMLDivElement).classList.remove('d-none');
        } else {
            (document.getElementById('roomTypeRow') as HTMLDivElement).classList.add('d-none');
        }

        if (this.semantics.type == AnnotationType.Region) {
            (document.getElementById('regionTypeRow') as HTMLDivElement).classList.remove('d-none');
        } else {
            (document.getElementById('regionTypeRow') as HTMLDivElement).classList.add('d-none');
        }

        if (this.semantics.type == AnnotationType.Exterior) {
            (document.getElementById('exteriorTypeRow') as HTMLDivElement).classList.remove('d-none');
        } else {
            (document.getElementById('exteriorTypeRow') as HTMLDivElement).classList.add('d-none');
        }

        if (this.semantics.type == AnnotationType.Openning) {
            (document.getElementById('openningTypeRow') as HTMLDivElement).classList.remove('d-none');
        } else {
            (document.getElementById('openningTypeRow') as HTMLDivElement).classList.add('d-none');
        }

        if (this.semantics.type == AnnotationType.Attachment) {
            (document.getElementById('attachmentTypeRow') as HTMLDivElement).classList.remove('d-none');
        } else {
            (document.getElementById('attachmentTypeRow') as HTMLDivElement).classList.add('d-none');
        }

        if (this.semantics.type == AnnotationType.Furniture) {
            (document.getElementById('furnitureTypeRow') as HTMLDivElement).classList.remove('d-none');
        } else {
            (document.getElementById('furnitureTypeRow') as HTMLDivElement).classList.add('d-none');
        } 

        this.canSubmit = this.semantics.type != AnnotationType.Other;
        this.syncSubmitButton();
    }

    didSubmit(form: HTMLFormElement): void {
        super.didSubmit(form);

        var title = (document.getElementById('annotationTitle') as HTMLInputElement).value;
        this.semantics.title = title.trim() === '' ? null : title;
        this.viewer.annotations.annotations.push(this.newAnnotation);
        this.viewer.updatedAnnotations();

        (document.getElementById('annotationTitle') as HTMLInputElement).value = '';
        (document.getElementById('annotationTypeSelect') as HTMLSelectElement).selectedIndex = 0;
        (document.getElementById('roomTypeSelect') as HTMLSelectElement).selectedIndex = 0;
        (document.getElementById('regionTypeSelect') as HTMLSelectElement).selectedIndex = 0;
        (document.getElementById('exteriorTypeSelect') as HTMLSelectElement).selectedIndex = 0;
        (document.getElementById('openningTypeSelect') as HTMLSelectElement).selectedIndex = 0;
        (document.getElementById('attachmentTypeSelect') as HTMLSelectElement).selectedIndex = 0;
        (document.getElementById('furnitureTypeSelect') as HTMLSelectElement).selectedIndex = 0;

    }
}

export class NewLevelModal extends FormModal {
    constructor(viewer: Viewer) {
        super("levelTitle", "newLevelModal", viewer);
    }

    didSubmit(form: HTMLFormElement): void {
        super.didSubmit(form);

        var title = (document.getElementById('levelTitle') as HTMLInputElement).value;
        var range = this.viewer.levelSlider.get() as number[];
        var level = new LevelT();
        level.title = title;
        level.bottom = range[0];
        level.top = range[1];
        level.exportable = (document.getElementById('levelExportableCheckbox') as HTMLInputElement).checked;
        level.aboveGrade = (document.getElementById('levelAboveGradeCheckbox') as HTMLInputElement).checked;
        this.viewer.annotations.levels.push(level);
        this.viewer.updatedAnnotations();

        (document.getElementById('levelTitle') as HTMLInputElement).value = '';
    }
}

