// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Corner, CornerT } from '../fbfloorplanner/corner.js';
import { Edge, EdgeT } from '../fbfloorplanner/edge.js';
import { Fragment, FragmentT } from '../fbfloorplanner/fragment.js';
import { Volume, VolumeT } from '../fbfloorplanner/volume.js';


export class ScanStructure implements flatbuffers.IUnpackableObject<ScanStructureT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ScanStructure {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsScanStructure(bb:flatbuffers.ByteBuffer, obj?:ScanStructure):ScanStructure {
  return (obj || new ScanStructure()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsScanStructure(bb:flatbuffers.ByteBuffer, obj?:ScanStructure):ScanStructure {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ScanStructure()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static bufferHasIdentifier(bb:flatbuffers.ByteBuffer):boolean {
  return bb.__has_identifier('STRC');
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

volume(obj?:Volume):Volume|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Volume()).__init(this.bb_pos + offset, this.bb!) : null;
}

fragments(index: number, obj?:Fragment):Fragment|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new Fragment()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 48, this.bb!) : null;
}

fragmentsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

edges(index: number, obj?:Edge):Edge|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new Edge()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 56, this.bb!) : null;
}

edgesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

corners(index: number, obj?:Corner):Corner|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new Corner()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 20, this.bb!) : null;
}

cornersLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startScanStructure(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(0, version, 0);
}

static addVolume(builder:flatbuffers.Builder, volumeOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, volumeOffset, 0);
}

static addFragments(builder:flatbuffers.Builder, fragmentsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, fragmentsOffset, 0);
}

static startFragmentsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(48, numElems, 4);
}

static addEdges(builder:flatbuffers.Builder, edgesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, edgesOffset, 0);
}

static startEdgesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(56, numElems, 4);
}

static addCorners(builder:flatbuffers.Builder, cornersOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, cornersOffset, 0);
}

static startCornersVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(20, numElems, 4);
}

static endScanStructure(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishScanStructureBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'STRC');
}

static finishSizePrefixedScanStructureBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'STRC', true);
}


unpack(): ScanStructureT {
  return new ScanStructureT(
    this.version(),
    (this.volume() !== null ? this.volume()!.unpack() : null),
    this.bb!.createObjList<Fragment, FragmentT>(this.fragments.bind(this), this.fragmentsLength()),
    this.bb!.createObjList<Edge, EdgeT>(this.edges.bind(this), this.edgesLength()),
    this.bb!.createObjList<Corner, CornerT>(this.corners.bind(this), this.cornersLength())
  );
}


unpackTo(_o: ScanStructureT): void {
  _o.version = this.version();
  _o.volume = (this.volume() !== null ? this.volume()!.unpack() : null);
  _o.fragments = this.bb!.createObjList<Fragment, FragmentT>(this.fragments.bind(this), this.fragmentsLength());
  _o.edges = this.bb!.createObjList<Edge, EdgeT>(this.edges.bind(this), this.edgesLength());
  _o.corners = this.bb!.createObjList<Corner, CornerT>(this.corners.bind(this), this.cornersLength());
}
}

export class ScanStructureT implements flatbuffers.IGeneratedObject {
constructor(
  public version: number = 0,
  public volume: VolumeT|null = null,
  public fragments: (FragmentT)[] = [],
  public edges: (EdgeT)[] = [],
  public corners: (CornerT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const fragments = builder.createStructOffsetList(this.fragments, ScanStructure.startFragmentsVector);
  const edges = builder.createStructOffsetList(this.edges, ScanStructure.startEdgesVector);
  const corners = builder.createStructOffsetList(this.corners, ScanStructure.startCornersVector);

  ScanStructure.startScanStructure(builder);
  ScanStructure.addVersion(builder, this.version);
  ScanStructure.addVolume(builder, (this.volume !== null ? this.volume!.pack(builder) : 0));
  ScanStructure.addFragments(builder, fragments);
  ScanStructure.addEdges(builder, edges);
  ScanStructure.addCorners(builder, corners);

  return ScanStructure.endScanStructure(builder);
}
}
