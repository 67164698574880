// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum AttachmentType {
  Other = 0,
  Outlet = 1,
  Vent = 2,
  Cabinet = 3,
  Beam = 4
}
