// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { AnnotationAction } from '../fbfloorplanner/annotation-action.js';


export class AnnotationMessage implements flatbuffers.IUnpackableObject<AnnotationMessageT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):AnnotationMessage {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsAnnotationMessage(bb:flatbuffers.ByteBuffer, obj?:AnnotationMessage):AnnotationMessage {
  return (obj || new AnnotationMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsAnnotationMessage(bb:flatbuffers.ByteBuffer, obj?:AnnotationMessage):AnnotationMessage {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new AnnotationMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

action():AnnotationAction {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : AnnotationAction.SaveAnnotation;
}

static startAnnotationMessage(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addAction(builder:flatbuffers.Builder, action:AnnotationAction) {
  builder.addFieldInt8(0, action, AnnotationAction.SaveAnnotation);
}

static endAnnotationMessage(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createAnnotationMessage(builder:flatbuffers.Builder, action:AnnotationAction):flatbuffers.Offset {
  AnnotationMessage.startAnnotationMessage(builder);
  AnnotationMessage.addAction(builder, action);
  return AnnotationMessage.endAnnotationMessage(builder);
}

unpack(): AnnotationMessageT {
  return new AnnotationMessageT(
    this.action()
  );
}


unpackTo(_o: AnnotationMessageT): void {
  _o.action = this.action();
}
}

export class AnnotationMessageT implements flatbuffers.IGeneratedObject {
constructor(
  public action: AnnotationAction = AnnotationAction.SaveAnnotation
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return AnnotationMessage.createAnnotationMessage(builder,
    this.action
  );
}
}
