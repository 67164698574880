// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Vector3, Vector3T } from '../fbmath/vector3.js';


export class TRXform2D5 implements flatbuffers.IUnpackableObject<TRXform2D5T> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TRXform2D5 {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

translation(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos, this.bb!);
}

orientation():number {
  return this.bb!.readFloat32(this.bb_pos + 12);
}

static sizeOf():number {
  return 16;
}

static createTRXform2D5(builder:flatbuffers.Builder, translation_x: number, translation_y: number, translation_z: number, orientation: number):flatbuffers.Offset {
  builder.prep(4, 16);
  builder.writeFloat32(orientation);
  builder.prep(4, 12);
  builder.writeFloat32(translation_z);
  builder.writeFloat32(translation_y);
  builder.writeFloat32(translation_x);
  return builder.offset();
}


unpack(): TRXform2D5T {
  return new TRXform2D5T(
    (this.translation() !== null ? this.translation()!.unpack() : null),
    this.orientation()
  );
}


unpackTo(_o: TRXform2D5T): void {
  _o.translation = (this.translation() !== null ? this.translation()!.unpack() : null);
  _o.orientation = this.orientation();
}
}

export class TRXform2D5T implements flatbuffers.IGeneratedObject {
constructor(
  public translation: Vector3T|null = null,
  public orientation: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return TRXform2D5.createTRXform2D5(builder,
    (this.translation?.x ?? 0),
    (this.translation?.y ?? 0),
    (this.translation?.z ?? 0),
    this.orientation
  );
}
}
