// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { AnnotationMessage, AnnotationMessageT } from '../fbfloorplanner/annotation-message.js';
import { HighlightAnnotation, HighlightAnnotationT } from '../fbfloorplanner/highlight-annotation.js';
import { HighlightFrame, HighlightFrameT } from '../fbfloorplanner/highlight-frame.js';
import { IncomingMessage, unionToIncomingMessage, unionListToIncomingMessage } from '../fbfloorplanner/incoming-message.js';
import { MeasurementMessage, MeasurementMessageT } from '../fbfloorplanner/measurement-message.js';
import { SurfaceMessage, SurfaceMessageT } from '../fbfloorplanner/surface-message.js';
import { ViewportChange, ViewportChangeT } from '../fbfloorplanner/viewport-change.js';


export class ToCpp implements flatbuffers.IUnpackableObject<ToCppT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ToCpp {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsToCpp(bb:flatbuffers.ByteBuffer, obj?:ToCpp):ToCpp {
  return (obj || new ToCpp()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsToCpp(bb:flatbuffers.ByteBuffer, obj?:ToCpp):ToCpp {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ToCpp()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static bufferHasIdentifier(bb:flatbuffers.ByteBuffer):boolean {
  return bb.__has_identifier('TOCP');
}

messageType():IncomingMessage {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : IncomingMessage.NONE;
}

message<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

static startToCpp(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addMessageType(builder:flatbuffers.Builder, messageType:IncomingMessage) {
  builder.addFieldInt8(0, messageType, IncomingMessage.NONE);
}

static addMessage(builder:flatbuffers.Builder, messageOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, messageOffset, 0);
}

static endToCpp(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishToCppBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'TOCP');
}

static finishSizePrefixedToCppBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'TOCP', true);
}

static createToCpp(builder:flatbuffers.Builder, messageType:IncomingMessage, messageOffset:flatbuffers.Offset):flatbuffers.Offset {
  ToCpp.startToCpp(builder);
  ToCpp.addMessageType(builder, messageType);
  ToCpp.addMessage(builder, messageOffset);
  return ToCpp.endToCpp(builder);
}

unpack(): ToCppT {
  return new ToCppT(
    this.messageType(),
    (() => {
      const temp = unionToIncomingMessage(this.messageType(), this.message.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })()
  );
}


unpackTo(_o: ToCppT): void {
  _o.messageType = this.messageType();
  _o.message = (() => {
      const temp = unionToIncomingMessage(this.messageType(), this.message.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
}
}

export class ToCppT implements flatbuffers.IGeneratedObject {
constructor(
  public messageType: IncomingMessage = IncomingMessage.NONE,
  public message: AnnotationMessageT|HighlightAnnotationT|HighlightFrameT|MeasurementMessageT|SurfaceMessageT|ViewportChangeT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const message = builder.createObjectOffset(this.message);

  return ToCpp.createToCpp(builder,
    this.messageType,
    message
  );
}
}
