import { Flags, Viewer } from './_nativeView'
import { transition, isMobile, setText, on, hideModal, isLocalHost, isLog } from './_utils'
import { ToCpp, ToCppT } from './flatbuffers/fbfloorplanner/to-cpp'
import { FromCpp, FromCppT } from './flatbuffers/fbfloorplanner/from-cpp'
import { SurfaceAction } from './flatbuffers/fbfloorplanner/surface-action'
import { SurfaceMessageT } from './flatbuffers/fbfloorplanner/surface-message'
import { MeasurementMessageT } from './flatbuffers/fbfloorplanner/measurement-message'
import { MeasurementAction } from './flatbuffers/fbfloorplanner/measurement-action'
import { ViewportChangeT } from './flatbuffers/fbfloorplanner/viewport-change'
import { Viewport } from './flatbuffers/fbfloorplanner/viewport'
import { Perspective } from './flatbuffers/fbfloorplanner/perspective'
import { Model } from './flatbuffers/fbfloorplanner/model'
import { Toast } from 'bootstrap'
import { IncomingMessage } from './flatbuffers/fbfloorplanner/incoming-message'
import { OutgoingMessage } from './flatbuffers/fbfloorplanner/outgoing-message'
import { NewAnnotationT } from './flatbuffers/fbfloorplanner/new-annotation'
import { NewMeasurementT } from './flatbuffers/fbfloorplanner/new-measurement'
import { show, hide } from './_utils'
import { HelpModal, SettingsModal, MeasurementsModal, AnnotationsModal, LevelsModal, NewMeasurementModal, NewAnnotationModal, NewLevelModal } from './_modals'

class State {
    constructor() {

    }

    willEnter() {

    }

    willExit() {

    }

    didEnter() {

    }

    didExit() {

    }
}

export class DomState extends State {
    protected viewer: Viewer;
    protected domId: string;

    constructor(domId: string, viewer: Viewer) { 
        super();
        this.domId = domId;
        this.viewer = viewer;
    }

    willEnter() {
        if (document.getElementById(this.domId)) {
            document.getElementById(this.domId)?.classList.remove('d-none');
        } else {
            console.error(`Element with id ${this.domId} not found`);
        }
    }

    willExit() {
        if (document.getElementById(this.domId)) {
            document.getElementById(this.domId)?.classList.add('d-none');
        } else {
            console.error(`Element with id ${this.domId} not found`);
        }
    }

    protected send(message: SurfaceAction) {
        var toCpp = new ToCppT();
        toCpp.messageType = IncomingMessage.SurfaceMessage;
        toCpp.message = new SurfaceMessageT();
        toCpp.message.action = message;
        this.viewer.dispatch(toCpp);            
    }
}   

export class Idle extends State {
    constructor() { 
        super();
    }
}   

var currentState: State = new Idle();

export function switchTo(state: State) {
    currentState.willExit();
    state.willEnter();

    var oldState = currentState;
    currentState = state;
    
    currentState.didEnter();
    oldState.didExit();
}



export class Main extends DomState {

    constructor(viewer: Viewer) {
        super("scanMainButton", viewer);

        this.setNavVisible(true);

        on('scanHelpButton', () => {
            var modal = new HelpModal(this.viewer);
        });

        on('scanCenterButton', () => {
            this.viewer.nativeViewer.center(true);
        });

        // on('scanSettingsButton', () => {
        //     var modal = new SettingsModal(this.viewer);
        // });

        on('snapshotsButton', () => {
            window.open('/snapshots.html?s=' + this.viewer.scanId, '_blank');
        });

        on('measureButton', () => {
            switchTo(new MeasuringMain(this.viewer));
            this.viewer.setFlags(this.viewer._flags | (Flags.kMeasuring | Flags.kHighlightMeasurements | Flags.kDetectPlanes));
            document.getElementById('hoverMeasurement')?.classList.remove('d-none');
            this.setNavVisible(false);
        });

        on('annotateButton', () => {
            switchTo(new AnnotatingMain(this.viewer));
            this.viewer.setFlags(this.viewer._flags | (Flags.kAnnotating | Flags.kHighlightAnnotations | Flags.kDetectPlanes));
            this.setNavVisible(false);
        });

        on('levelsButton', () => {
            switchTo(new LevelsMain(this.viewer));
            this.viewer.setFlags(this.viewer._flags | (Flags.kSlicing));
            this.setNavVisible(false);
        });

        on('fullScreenButton', () => {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                document.documentElement.requestFullscreen();
            }
        });

        on('showFrontButton', () => {
            this.viewer.setFlags(this.viewer._flags ^ Flags.kShowModelFront);
            (document.getElementById('showFrontButton') as HTMLLinkElement).innerText = (this.viewer._flags & Flags.kShowModelFront) ? 'Hide Front' : 'Show Front';
        });

        on('showWireframeButton', () => {
            this.viewer.setFlags(this.viewer._flags ^ Flags.kShowWireframe);
            (document.getElementById('showWireframeButton') as HTMLLinkElement).innerText = (this.viewer._flags & Flags.kShowWireframe) ? 'Hide Wireframe' : 'Show Wireframe';
        });

        on('perspectiveButton', () => {
            this.viewer.perspective = !this.viewer.perspective;

            // Animate the camera view.
            var toCpp = new ToCppT();
            toCpp.messageType = IncomingMessage.ViewportChange;
            toCpp.message = new ViewportChangeT();
            toCpp.message.perspective = this.viewer.perspective ? Perspective.Perspective : Perspective.Orthographic;
            toCpp.message.viewport = Viewport.Canonical;
            toCpp.message.animated = true;
            this.viewer.dispatch(toCpp);

            (document.getElementById('perspectiveButton') as HTMLLinkElement).innerText = this.viewer.perspective ? 'Orthographic View' : 'Perspective View';
        });
    }    

    setNavVisible(visible: boolean) {
        if (visible) {
            document.getElementById('navbar')?.classList.add('show');
            document.getElementById('scanDetails')?.classList.add('show');
        } else {
            document.getElementById('navbar')?.classList.remove('show');
            document.getElementById('scanDetails')?.classList.remove('show');
        }
    }
}


export class MeasuringMain extends DomState {
    constructor(viewer: Viewer) {
        super("measurementMain", viewer);

        on('measurementsLevelsListButton', () => {
            var modal = new LevelsModal(this.viewer);
        });

        on('measurementsPathButton', () => {
            switchTo(new MeasuringPath(this.viewer));
            this.viewer.setFlags(this.viewer._flags | Flags.kMeasurementAdding);
        });

        on('measurementsRaycastButton', () => {
            switchTo(new MeasuringRaycast(this.viewer));
            this.viewer.setFlags(this.viewer._flags | Flags.kRaycasting);
        });

        on('measurementCloseButton', () => {
            switchTo(new Main(this.viewer));
            this.viewer.setFlags(this.viewer._flags ^ (Flags.kMeasuring | Flags.kHighlightMeasurements | Flags.kDetectPlanes));
            document.getElementById('hoverMeasurement')?.classList.add('d-none');
        });

        on('measurementsFocusButton', () => {
            switchTo(new MeasuringFocussedMain(this.viewer));
            this.send(SurfaceAction.SelectMeasurePlane);
            this.viewer.syncCameraSnapFlags();
            this.viewer.setFlags(this.viewer._flags ^ (Flags.kDetectPlanes));
            show('cameraSnapMain');
        });

        on('measurementsButton', () => {
            var modal = new MeasurementsModal(this.viewer);
        });
    }
}

export class MeasuringPath extends DomState {
    constructor(viewer: Viewer) {
        super("measurementPath", viewer);

        on('measuringPathLoopButton', () => {
            this.viewer.setFlags(this.viewer._flags ^ Flags.kMeasurementLoop);
        });

        on('measuringPathAddPointButton', () => {
            var toCpp = new ToCppT();
            toCpp.messageType = IncomingMessage.MeasurementMessage;
            toCpp.message = new MeasurementMessageT();
            toCpp.message.action = MeasurementAction.AddPoint;
            this.viewer.dispatch(toCpp);         
        });

        on('measuringPathRemovePointButton', () => {
            var toCpp = new ToCppT();
            toCpp.messageType = IncomingMessage.MeasurementMessage;
            toCpp.message = new MeasurementMessageT();
            toCpp.message.action = MeasurementAction.RemoveLastPoint;
            this.viewer.dispatch(toCpp);         
        });

        on('measuringPathSaveButton', () => {
            var modal = new NewMeasurementModal(this.viewer);
        });

        on('measuringPathCancelButton', () => {
            switchTo(new MeasuringMain(this.viewer));
            this.viewer.setFlags(this.viewer._flags ^ Flags.kMeasurementAdding);
        });
    }
}

export class MeasuringRaycast extends DomState {
    constructor(viewer: Viewer) {
        super("measurementRaycast", viewer);

        on('measuringRaycastSaveButton', () => {
            var modal = new NewMeasurementModal(this.viewer);
        });

        on('measuringRaycastCancelButton', () => {
            switchTo(new MeasuringMain(this.viewer));
            this.viewer.setFlags(this.viewer._flags ^ Flags.kRaycasting);
        });
    }
}

export class MeasuringFocussedMain extends DomState {
    constructor(viewer: Viewer) {
        super("measurementFocussed", viewer);

        document.querySelectorAll('.surfaceCenterButton').forEach(button => {
            (button as HTMLButtonElement).onclick = () => {
                this.viewer.nativeViewer.center(true);
            };
        });

        document.querySelectorAll('.surfaceSetDefaultOrientationButton').forEach(button => {
            (button as HTMLButtonElement).onclick = () => {
                this.viewer.nativeViewer.setDefaultOrientation();
                Toast.getOrCreateInstance(document.getElementById('setDefaultOrientationToast') as HTMLElement).show();
            };
        });

        on('measurementFocussedAddRectButton', () => {
            switchTo(new MeasuringRecting(this.viewer, true));
            this.send(SurfaceAction.AdditiveStart);
        });

        on('measurementFocussedSubRectButton', () => {
            switchTo(new MeasuringRecting(this.viewer, false));
            this.send(SurfaceAction.SubtractiveStart);
        });

        on('measurementFocussedSaveButton', () => {
            var modal = new NewMeasurementModal(this.viewer);
        });

        on('measurementFocussedCloseButton', () => {
            switchTo(new MeasuringMain(this.viewer));
            this.send(SurfaceAction.ReleaseAnnotationPlane);
            this.viewer.setFlags((this.viewer._flags | (Flags.kDetectPlanes)) & ~(Flags.kRotationLocked | Flags.kTranslationLocked | Flags.kIsOrientationSnappinEnabled | Flags.kIsPositionSnappinEnabled));
            hide('cameraSnapMain');
        });
    }
}

export class MeasuringRecting extends DomState {
    private isAdding: boolean = false;

    constructor(viewer: Viewer, isAdding: boolean) {
        super("measurementRecting", viewer);
        this.isAdding = isAdding;

        on('measurementRectingCancelButton', () => {
            this.send(SurfaceAction.Cancel);
            switchTo(new MeasuringFocussedMain(this.viewer));
        });

        on('measurementRectingDoneButton', () => {
            this.send(SurfaceAction.Commit);
            switchTo(new MeasuringFocussedMain(this.viewer));
        });
    }
}



export class AnnotatingMain extends DomState {
    constructor(viewer: Viewer) {
        super("annotationsMain", viewer);

        on('annotationsLevelsListButton', () => {
            var modal = new LevelsModal(this.viewer);
        });

        on('annotationsNewPoiontAnnotationButton', () => {
            var modal = new NewAnnotationModal(this.viewer);
        });

        on('annotationsCloseButton', () => {
            switchTo(new Main(this.viewer));
            this.viewer.setFlags(this.viewer._flags ^ (Flags.kAnnotating | Flags.kHighlightAnnotations | Flags.kDetectPlanes));
        });

        on('annotationsFocusButton', () => {
            switchTo(new AnnotationsFocussedMain(this.viewer));
            this.send(SurfaceAction.SelectAnnotationPlane);
            this.viewer.syncCameraSnapFlags();
            this.viewer.setFlags(this.viewer._flags ^ (Flags.kDetectPlanes));
            show('cameraSnapMain');
        });

        on('annotationsButton', () => {
            var modal = new AnnotationsModal(this.viewer);
        });
    }
}

export class AnnotationsFocussedMain extends DomState {
    constructor(viewer: Viewer) {
        super("annotationsFocussed", viewer);

        document.querySelectorAll('.surfaceCenterButton').forEach(button => {
            (button as HTMLButtonElement).onclick = () => {
                this.viewer.nativeViewer.center(true);
            };
        });

        document.querySelectorAll('.surfaceSetDefaultOrientationButton').forEach(button => {
            (button as HTMLButtonElement).onclick = () => {
                this.viewer.nativeViewer.setDefaultOrientation();
                Toast.getOrCreateInstance(document.getElementById('setDefaultOrientationToast') as HTMLElement).show();
            };
        });

        on('annotationsFocussedAddRectButton', () => {
            switchTo(new AnnotationRecting(this.viewer, true));
            this.send(SurfaceAction.AdditiveStart);
        });

        on('annotationsFocussedSubRectButton', () => {
            switchTo(new AnnotationRecting(this.viewer, false));
            this.send(SurfaceAction.SubtractiveStart);
        });

        on('annotationsFocussedSaveButton', () => {
            var modal = new NewAnnotationModal(this.viewer);
        });

        on('annotationsFocussedCloseButton', () => {
            switchTo(new AnnotatingMain(this.viewer));
            this.send(SurfaceAction.ReleaseAnnotationPlane);
            this.viewer.setFlags((this.viewer._flags | (Flags.kDetectPlanes)) & ~(Flags.kRotationLocked | Flags.kTranslationLocked | Flags.kIsOrientationSnappinEnabled | Flags.kIsPositionSnappinEnabled));
            hide('cameraSnapMain');
        });
    }
}

export class AnnotationRecting extends DomState {
    private isAdding: boolean = false;

    constructor(viewer: Viewer, isAdding: boolean) {
        super("annotationRecting", viewer);
        this.isAdding = isAdding;

        on('annotationRectingCancelButton', () => {
            this.send(SurfaceAction.Cancel);
            switchTo(new AnnotationsFocussedMain(this.viewer));
        });

        on('annotationRectingDoneButton', () => {
            this.send(SurfaceAction.Commit);
            switchTo(new AnnotationsFocussedMain(this.viewer));
        });
    }
}



export class LevelsMain extends DomState {
    constructor(viewer: Viewer) {
        super("levelSelector", viewer);

        on('levelsListButton', () => {
            var modal = new LevelsModal(this.viewer);
        });

        on('levelAddButton', () => {
            var modal = new NewLevelModal(this.viewer);
        });

        on('levelResetButton', () => {
            this.viewer.levelSlider.set([this.viewer.bottom, this.viewer.top]);
        });

        on('levelsCloseButton', () => {
            this.viewer.setFlags(this.viewer._flags ^ (Flags.kSlicing));
            switchTo(new Main(this.viewer));
        });
    }
}

