// ImageSpace main entry point.
import { firebaseApp } from './firebase/_app';
import { setupAuth } from './firebase/_auth';
import { Tooltip, Toast, Popover, Carousel, Modal } from 'bootstrap';
import { firebaseStorage } from './firebase/_storage'
import { User } from 'firebase/auth'
import { getStorage, ref, getBytes, getDownloadURL } from "firebase/storage"
import { collection, doc, getDoc, setDoc, DocumentSnapshot } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { firebaseDb } from './firebase/_database'
import { transition, isMobile, setText, hideModal, isLocalHost, isLog, showError } from './_utils'
import { Scan, ScanT } from 'src/flatbuffers/fbfloorplanner/scan'
import { ToCpp, ToCppT } from 'src/flatbuffers/fbfloorplanner/to-cpp'
import { FromCpp, FromCppT } from 'src/flatbuffers/fbfloorplanner/from-cpp'
import { SurfaceAction } from 'src/flatbuffers/fbfloorplanner/surface-action'
import { SurfaceMessageT } from 'src/flatbuffers/fbfloorplanner/surface-message'
import { ViewportChangeT } from 'src/flatbuffers/fbfloorplanner/viewport-change'
import { Viewport } from 'src/flatbuffers/fbfloorplanner/viewport'
import { Perspective } from 'src/flatbuffers/fbfloorplanner/perspective'
import { Model } from 'src/flatbuffers/fbfloorplanner/model'
import {} from "firebase/firestore"
import * as flatbuffers from 'flatbuffers';
import { FirebaseError } from 'firebase/app'

import { IncomingMessage } from 'src/flatbuffers/fbfloorplanner/incoming-message'
import { OutgoingMessage } from 'src/flatbuffers/fbfloorplanner/outgoing-message'
import { NewAnnotationT } from 'src/flatbuffers/fbfloorplanner/new-annotation'
import { NewMeasurementT } from 'src/flatbuffers/fbfloorplanner/new-measurement'

import { PendingTexture, Viewer } from './_nativeView'
import { Main, switchTo } from './_states'
import { user, addUserChangeHandler } from './firebase/_auth'
import { loadSnapshot } from './_snapshot'
import { loadSnapshotsFor } from './_snapshots'
// Check if SharedArrayBuffer is supported
const isSharedArrayBufferSupported = (() => {
    try {
        new SharedArrayBuffer(1);
        return true;
    } catch (e) {
        return false;
    }
})();

if (isSharedArrayBufferSupported) {
    isLog("SharedArrayBuffer is supported");
} else {
    isLog("SharedArrayBuffer is not supported");
}


// These are exported from floorplanner/Web/post.js.
// These functions bridge C++ and javascript.
declare global {

    interface NativeViewer {
        setImageSpaceData:(data: Uint8Array) => Uint8Array
        setWebPForAtlas:(atlasIndex: number, webpData: ArrayBuffer) => void
        setFlags:(flags: number) => void
        setVerticalZRange:(bottom: number, top: number) => void
        setHandleKeyboardEvents:(handle: boolean) => void
        metricsFor:(table: boolean, annotation: boolean, id: number) => string
        stringForLength:(length: number) => string
        stringForArea:(area: number) => string
        center:(animated: boolean) => void
        setDefaultOrientation:() => void
        onHoverChanged: (newId: number) => void;
        onOutFlagsChanged: (newFlags: number) => void;
        imageSpaceReady: () => void;
    }

    function Module(): Promise<NativeViewer>;
}


var pendingBytesArray: ArrayBuffer[] = [];
var pendingTextures: PendingTexture[] = [];
var viewer: Viewer | null = null;

function dispatchFlatbuffer(bytes: ArrayBuffer) {
    if (viewer != null) {
        viewer.dispatchFlatbuffer(bytes);
    } else {
        pendingBytesArray.push(bytes);
    }
}

function dispatchTexture(atlasIndex: number, url: string) {
    isLog(`Dispatching texture ${atlasIndex} from URL: ${url}`);
    fetch(url)
        .then(response => response.blob())
        .then(blob => blob.arrayBuffer())
        .then(buffer => {
            if (viewer != null) {
                viewer.setWebPForAtlas(atlasIndex, buffer);
            } else {
                pendingTextures = pendingTextures.filter(texture => texture.atlasIndex != atlasIndex);

                pendingTextures.push({
                    atlasIndex,
                    webP: buffer
                });
            }
        })
        .catch(error => {
            showError(`Error downloading texture ${atlasIndex}: ${error}`);
        });
}

  
function mapUrlForLatLong(latitude: number, longitude: number, address: string | null) {
    return "https://www.google.com/maps/search/?api=1&query=" + latitude + "," + longitude;
}   

function updateMetaProperty(property: string, value: string) {
    const metaTag = document.querySelector(`meta[property="${property}"]`);
    if (metaTag) {
        metaTag.setAttribute('content', value);
    }
}

async function loadScanData(scanId: string, scanData: DocumentSnapshot) {

    // Fetch the data.
    const data = scanData.data();
    if (data == null) {
        return;
    }

    // Update the meta tags with the scan data.
    updateMetaProperty('description', data['title'] ?? data['address'] ?? "3D scan created by the ImageSpace mobile app.");
    updateMetaProperty('og:description', data['title'] ?? data['address'] ?? "3D scan created by the ImageSpace mobile app.");

    // Figure out the title for the scan.
    var title = data['title'];

    // Format the address line.
    if ((data['latitude'] as number != 0) && (data['longitude'] as number != 0)) {
        let url = mapUrlForLatLong(data['latitude'] as number, data['longitude'] as number, data['address'] as string);

        title = title ?? (data['address'] as string)?.split('\n')[0] ?? "Untitled scan";

        title = "<a href=" + url + " target=\"_blank\"><i class=\"bi bi-pin-map\"></i></a> " + title;
    } else {
        document.getElementById('scanAddress')?.classList.add('d-none');
    }

    // Set the title.
    setText('scanTitle', title ?? "Untitled scan");

    // Setup the date time.
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    setText('scanDateTime', data['timestamp'].toDate().toLocaleString(undefined, options));
    document.getElementById('scanDetails')?.classList.remove('d-none');
    
    // Figure out how mant atlass pages we have
    const atlasCount = data['numAtlasPages'] ?? 0;
    if (atlasCount == 0) {
        showError("No atlas pages found for scan " + scanId);
        return;
    }

    // Function to load textures
    const loadTextures = (resolution: string) => {
        const promises = [];
        for (let atlasIndex = 0; atlasIndex < atlasCount; atlasIndex++) {
            const fileName = `scans/${scanId}/Textures/atlas_${atlasIndex}${resolution}.webp`;
            const promise = getDownloadURL(ref(firebaseStorage, fileName))
                .then(webpURL => {
                    dispatchTexture(atlasIndex, webpURL);
                })
                .catch(error => {
                    showError(`Error loading ${resolution === '_8' ? 'low-res' : 'high-res'} texture for atlas ${atlasIndex}: ${error}`);
                    // Attempt to load a placeholder texture or skip this texture
                    return Promise.resolve();

                });
            promises.push(promise);
        }
        return Promise.all(promises);
    };

    // Load low resolution textures first, then high resolution
    loadTextures('_8')
        .then(() => {
            isLog("Low-res textures loaded. Waiting 1 second before loading high-res textures...");
            document.getElementById('loadingSpinner')?.classList.add('d-none');
            return new Promise(resolve => setTimeout(resolve, 1000));
        })
        .then(() => {
            isLog("Loading high-res textures...");
            return loadTextures('');
        })
        .then(() => {
            isLog("All textures loaded successfully.");
        })
        .catch(error => {
            console.error("Error in texture loading process:", error);
        });

    // Load and dispatch the flatbuffers.
    var flatbuffers =[ "model.fb", "structure.fb", "annotations.fb", "scan.fb"];
    for (const flatbuffer of flatbuffers) {
        getBytes(ref(firebaseStorage, 'scans/' + scanId + '/' + flatbuffer)).then((bytes: ArrayBuffer) => {
            dispatchFlatbuffer(bytes);
        })
        .catch((error) => {
            //showError(error);
            isLog(error);
        });
    }

    // Wait until main is called on the module.
    const module = await Module();
    module.imageSpaceReady = () => {
        viewer = new Viewer(scanId, module, pendingBytesArray, pendingTextures);

        if (user) {
            viewer.downloadAnnotationsForUser(user);
        }

        switchTo(new Main(viewer));
    }

    module.onOutFlagsChanged = (newFlags: number) => {
        viewer?.onOutFlagsChanged(newFlags);
    }

    module.onHoverChanged = (newId: number) => {
        viewer?.onHoverChanged(newId);
    }
}


async function checkProgress(scanId: string, modal: Modal|null) {
    const scanDoc = await getDoc(doc(firebaseDb, 'scans', scanId));
    if (scanDoc.exists() && ('processedAt' in scanDoc.data())) {
        // Kill the modal if we are showing it.
        modal?.hide();

        // Read to download the scan data.
        loadScanData(scanId, scanDoc);
    } else {
        if (modal == null) {
            var element = document.getElementById('processingModal');
            if (element) {
                modal = new Modal(element);
                modal.show();
            }
        }

        // Wait for 2 seconds and check again.
        setTimeout(async () => {
            await checkProgress(scanId, modal);
        }, 2000);
    }
}

// Register the user change handler
addUserChangeHandler((user: User | null) => {
    if (user) {
        viewer?.downloadAnnotationsForUser(user);
    }
});


export async function loadScan(scanId: string) {
    isLog("Loading scan " + scanId);

    if (isMobile.any()) {
        document.getElementById('desktopControls')?.classList.add('d-none');
    } else {
        document.getElementById('mobileControls')?.classList.add('d-none');
    }

    getDownloadURL(ref(firebaseStorage, "scans/" + scanId + "/thumbnail_4.webp"))
            .then((url) => {
                updateMetaProperty('og:image', url);
            })
            .catch((error) => {
                isLog("Scan thumbnail was not found:", error);
            });
    
    // Get the scans from the database.
    getDoc(doc(firebaseDb, 'scans', scanId)).then((scanDoc) => {
        if (scanDoc.exists()) {

            // Is the scan processed already?
            if ('processedAt' in scanDoc.data()) {
                loadScanData(scanId, scanDoc);
            } else {
                checkProgress(scanId, null);
            }

            // Get the user profile image.
            getDownloadURL(ref(firebaseStorage, "users/" + scanDoc.data()['uid'] + "/profile_4.webp"))
            .then((url) => {
                setText('scanUserIcon', url);
                document.querySelectorAll('.scanUserIcon').forEach(element => {
                    element.classList.remove('d-none');
                });
            })
            .catch((error) => {
                isLog("User profile image not found:", error);
            });

            // Get the user data.
            getDoc(doc(firebaseDb, 'users', scanDoc.data()['uid'])).then((userDoc) => {
                if (userDoc.exists()) {
                    setText("scanUserName", userDoc.data()['displayName']);
                }
            });
        } else {
            // Redirect to 404
            window.location.href = "/404.html";
        }
    });

}

