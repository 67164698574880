// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Vector3, Vector3T } from '../fbmath/vector3.js';


export class Edge implements flatbuffers.IUnpackableObject<EdgeT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Edge {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

point0(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos, this.bb!);
}

point1(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos + 12, this.bb!);
}

extrude0(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos + 24, this.bb!);
}

extrude1(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos + 36, this.bb!);
}

fragment0():number {
  return this.bb!.readUint16(this.bb_pos + 48);
}

fragment1():number {
  return this.bb!.readUint16(this.bb_pos + 50);
}

corner0():number {
  return this.bb!.readUint16(this.bb_pos + 52);
}

corner1():number {
  return this.bb!.readUint16(this.bb_pos + 54);
}

static sizeOf():number {
  return 56;
}

static createEdge(builder:flatbuffers.Builder, point0_x: number, point0_y: number, point0_z: number, point1_x: number, point1_y: number, point1_z: number, extrude0_x: number, extrude0_y: number, extrude0_z: number, extrude1_x: number, extrude1_y: number, extrude1_z: number, fragment0: number, fragment1: number, corner0: number, corner1: number):flatbuffers.Offset {
  builder.prep(4, 56);
  builder.writeInt16(corner1);
  builder.writeInt16(corner0);
  builder.writeInt16(fragment1);
  builder.writeInt16(fragment0);
  builder.prep(4, 12);
  builder.writeFloat32(extrude1_z);
  builder.writeFloat32(extrude1_y);
  builder.writeFloat32(extrude1_x);
  builder.prep(4, 12);
  builder.writeFloat32(extrude0_z);
  builder.writeFloat32(extrude0_y);
  builder.writeFloat32(extrude0_x);
  builder.prep(4, 12);
  builder.writeFloat32(point1_z);
  builder.writeFloat32(point1_y);
  builder.writeFloat32(point1_x);
  builder.prep(4, 12);
  builder.writeFloat32(point0_z);
  builder.writeFloat32(point0_y);
  builder.writeFloat32(point0_x);
  return builder.offset();
}


unpack(): EdgeT {
  return new EdgeT(
    (this.point0() !== null ? this.point0()!.unpack() : null),
    (this.point1() !== null ? this.point1()!.unpack() : null),
    (this.extrude0() !== null ? this.extrude0()!.unpack() : null),
    (this.extrude1() !== null ? this.extrude1()!.unpack() : null),
    this.fragment0(),
    this.fragment1(),
    this.corner0(),
    this.corner1()
  );
}


unpackTo(_o: EdgeT): void {
  _o.point0 = (this.point0() !== null ? this.point0()!.unpack() : null);
  _o.point1 = (this.point1() !== null ? this.point1()!.unpack() : null);
  _o.extrude0 = (this.extrude0() !== null ? this.extrude0()!.unpack() : null);
  _o.extrude1 = (this.extrude1() !== null ? this.extrude1()!.unpack() : null);
  _o.fragment0 = this.fragment0();
  _o.fragment1 = this.fragment1();
  _o.corner0 = this.corner0();
  _o.corner1 = this.corner1();
}
}

export class EdgeT implements flatbuffers.IGeneratedObject {
constructor(
  public point0: Vector3T|null = null,
  public point1: Vector3T|null = null,
  public extrude0: Vector3T|null = null,
  public extrude1: Vector3T|null = null,
  public fragment0: number = 0,
  public fragment1: number = 0,
  public corner0: number = 0,
  public corner1: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Edge.createEdge(builder,
    (this.point0?.x ?? 0),
    (this.point0?.y ?? 0),
    (this.point0?.z ?? 0),
    (this.point1?.x ?? 0),
    (this.point1?.y ?? 0),
    (this.point1?.z ?? 0),
    (this.extrude0?.x ?? 0),
    (this.extrude0?.y ?? 0),
    (this.extrude0?.z ?? 0),
    (this.extrude1?.x ?? 0),
    (this.extrude1?.y ?? 0),
    (this.extrude1?.z ?? 0),
    this.fragment0,
    this.fragment1,
    this.corner0,
    this.corner1
  );
}
}
