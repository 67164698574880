import { initializeApp } from 'firebase/app';
import { User, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, sendPasswordResetEmail, onAuthStateChanged,initializeAuth, indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence, deleteUser, updateProfile } from 'firebase/auth'
import { doc, collection, getDoc, deleteDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { firebaseApp } from './_app'
import { Modal, Toast } from 'bootstrap';
import { firebaseDb } from './_database';
import { firebaseStorage } from './_storage';
import { isLog, showError } from '../_utils';
// The authentication object.
export const firebaseAuth = initializeAuth(firebaseApp, {
    persistence: [
      indexedDBLocalPersistence,
      browserLocalPersistence,
      browserSessionPersistence,
    ],
  });

// This does not work with site isolation.
//export const firebaseAuth = getAuth(firebaseApp)

// The current user.
export let user : User | null = null;

// Create a type for the user change handler
export type UserChangeHandler = (user: User | null) => void;

// Initialize an array to store multiple handlers
const userChangeHandlers: UserChangeHandler[] = [];

// Export function to add handlers
export function addUserChangeHandler(handler: UserChangeHandler) {
    userChangeHandlers.push(handler);
}

// Export function to remove handlers
export function removeUserChangeHandler(handler: UserChangeHandler) {
    const index = userChangeHandlers.indexOf(handler);
    if (index > -1) {
        userChangeHandlers.splice(index, 1);
    }
}

export function setupAuth() {
    // Handle user change.
    onAuthStateChanged(firebaseAuth, (loggedInUser) => {
        if (loggedInUser) {
            (document.getElementById('navLoginButton') as HTMLLinkElement).classList.add('d-none');
            (document.getElementById('accountDropDown') as HTMLLIElement).classList.remove('d-none');

            setupAccountPage(loggedInUser);
        } else {
            (document.getElementById('navLoginButton') as HTMLLinkElement).classList.remove('d-none');
            (document.getElementById('accountDropDown') as HTMLLIElement).classList.add('d-none');

            // Cannot be here not logged in.
            if ((window.location.pathname == '/scans.html') || (window.location.pathname == '/account.html')) {
                window.location.href = '/index.html';
            }
        }
        user = loggedInUser;

        refreshMyScans();

        // Notify all registered handlers
        userChangeHandlers.forEach(handler => handler(loggedInUser));
    });

    (document.getElementById('navLogoutButton') as HTMLLinkElement).onclick = () => {
        signOut(firebaseAuth).then(() => {
            isLog("Signed out");
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            window.location.href = '/index.html';
        });
    };

    // Setuo the UI here.
    (document.getElementById('loginForm') as HTMLFormElement).onsubmit = (event) => {
        event.preventDefault();
        const email = (document.getElementById('loginEmail') as HTMLInputElement).value;
        const password = (document.getElementById('loginPassword') as HTMLInputElement).value;

        (document.getElementById('loginError') as HTMLDivElement).classList.add('d-none');
        signInWithEmailAndPassword(firebaseAuth, email, password).then(() => {
            Modal.getInstance(document.getElementById('loginModal') as HTMLElement)?.hide();
        }).catch((error) => {   
            (document.getElementById('loginError') as HTMLDivElement).innerHTML = error.message;
            (document.getElementById('loginError') as HTMLDivElement).classList.remove('d-none');
        });
    };


    (document.getElementById('signUpForm') as HTMLFormElement).onsubmit = (event) => {
        event.preventDefault();
        const email = (document.getElementById('signUpEmail') as HTMLInputElement).value;
        const password = (document.getElementById('signUpPassword') as HTMLInputElement).value;
        
        (document.getElementById('signUpError') as HTMLDivElement).classList.add('d-none');
        createUserWithEmailAndPassword(firebaseAuth, email, password).then(() => {
            Modal.getInstance(document.getElementById('signUpModal') as HTMLElement)?.hide();
            new Modal(document.getElementById('welcomeToImageSpaceModal') as HTMLElement).show();
        }).catch((error) => {
            (document.getElementById('signUpError') as HTMLDivElement).innerHTML = error.message;
            (document.getElementById('signUpError') as HTMLDivElement).classList.remove('d-none');
        });
    };


    (document.getElementById('forgotForm') as HTMLFormElement).onsubmit = (event) => {
        event.preventDefault();
        const email = (document.getElementById('forgotEmail') as HTMLInputElement).value;
        (document.getElementById('forgotError') as HTMLDivElement).classList.add('d-none');
        sendPasswordResetEmail(firebaseAuth, email).then(() => {
            Modal.getInstance(document.getElementById('forgotModal') as HTMLElement)?.hide();
            new Modal(document.getElementById('forgotSentEmailModal') as HTMLElement).show();
        }).catch((error) => {
            (document.getElementById('forgotError') as HTMLDivElement).innerHTML = error.message;
            (document.getElementById('forgotError') as HTMLDivElement).classList.remove('d-none');
        });
    };
}

async function refreshMyScans() {
    if (user) {
        var scansList = document.getElementById('scansList') as HTMLDivElement;
        if (scansList) {
            scansList.innerHTML = '';
            var userDoc = doc(collection(firebaseDb, 'users'), user.uid);

            var docSnap = await getDoc(userDoc);
            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.scans && (data.scans.length > 0)) {

                    // Setup the placeholder for the cards.
                    scansList.innerHTML = data.scans.map((scan: any) => {
                        return `<div id="snapshot${scan}" class="col-md-4"></div>`;
                    }).join('');

                    // Start loading the cards.
                    data.scans.forEach((scan: any) => {
                        addScan(scansList, scan);
                    });
                } else {
                    scansList.innerHTML = '<div class="alert alert-warning" role="alert">You have no scans uploaded. You can use the <a href="https://apps.apple.com/us/app/imagespace/id6443640402" target="_blank">ImageSpace app</a> to upload your scans here.</div>';
                }
            }
        }   
    }
}

async function addScan(scansDiv: HTMLDivElement, scan: string) {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    var scanThumbnail = await getDownloadURL(ref(firebaseStorage, `scans/${scan}/thumbnail_4.webp`));
    var scanRecord = await doc(collection(firebaseDb, 'scans'), scan);
    var scanRecordData = await getDoc(scanRecord);
    var timestampString = scanRecordData.data()?.timestamp.toDate().toLocaleString(undefined, options);
    var snapshot = scanRecordData.data();
    (document.getElementById(`snapshot${scan}`) as HTMLDivElement).innerHTML = `<div class="card mb-4">
                                            <img src="${scanThumbnail}" class="card-img-top scan_thumb" alt="Scan thumbnail" crossorigin="anonymous">
                                            <div class="card-body">
                                                <h5 class="card-title">${snapshot?.title ?? snapshot?.address ?? "Untitled"}</h5>
                                                <p class="card-text">${timestampString}</p>
                                                <a href="/viewer.html?s=${scan}" class="btn btn-primary stretched-link">View</a>
                                            </div>
                                        </div>
                                    </div>`;
}


async function setupAccountPage(user: User) {
    var form = document.getElementById('accountForm') as HTMLFormElement;
    if (form) {
        if (user.emailVerified) {
            (document.getElementById('verifiedEmailContainer') as HTMLDivElement).classList.remove('d-none');
            (document.getElementById('verifiedEmail') as HTMLSpanElement).innerHTML = user.email ?? "";
        } else {
            (document.getElementById('unverifiedEmailContainer') as HTMLDivElement).classList.remove('d-none');
            (document.getElementById('unverifiedEmail') as HTMLSpanElement).innerHTML = user.email ?? "";
        }

        (document.getElementById('displayName') as HTMLInputElement).value = user.displayName ?? "";

        form.onsubmit = (event) => {
            event.preventDefault();
            const displayName = (document.getElementById('displayName') as HTMLInputElement).value;
            if (displayName != user.displayName) {
                setAccountProgress(true);
                updateProfile(user, { displayName: displayName })
                .then(() => {
                    Toast.getOrCreateInstance(document.getElementById('accountSavedToast') as HTMLElement)?.show();
                }).catch((error) => {
                    showError(error);
                }).finally(() => {
                    setAccountProgress(false);
                });
            }
        };

        getDownloadURL(ref(firebaseStorage, `users/${user.uid}/profile.webp`))
        .then((url) => {
            (document.getElementById('userProfileImage') as HTMLImageElement).src = url;
        })
        .catch((error) => {
            // This is okay.
            isLog("No profile picture for user " + user.uid);
        });

        (document.getElementById('changeProfilePicButton') as HTMLButtonElement).onclick = () => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';
            fileInput.onchange = (event) => {
                const file = (event.target as HTMLInputElement).files?.[0];
                if (file) { 
                    const storageRef = ref(firebaseStorage, `users/${user.uid}/profile.${file.name.split('.').pop()}`);
                    uploadBytes(storageRef, file)
                    .then((snapshot) => {
                        getDownloadURL(snapshot.ref)
                        .then((url) => {
                            document.getElementById('userProfileImage')?.setAttribute('src', url);
                            Toast.getOrCreateInstance(document.getElementById('accountSavedToast') as HTMLElement)?.show();
                        });
                    });
                }
            };
            fileInput.click();
        };

        (document.getElementById('confirmDeleteAccount') as HTMLButtonElement).onclick = () => {
            setAccountProgress(true);
            deleteImageSpaceUser(user).then(() => {
                window.location.href = '/index.html';
            }).catch((error) => {
                showError(error);
            }).finally(() => {
                setAccountProgress(false);
            });
        };
    }
}

function setAccountProgress(isWorking: boolean) {
    if (isWorking) {
        (document.getElementById('progressContainer') as HTMLDivElement).classList.remove('d-none');
        (document.getElementById('accountFormContainer') as HTMLDivElement).classList.add('d-none');
    } else {
        (document.getElementById('progressContainer') as HTMLDivElement).classList.add('d-none');
        (document.getElementById('accountFormContainer') as HTMLDivElement).classList.remove('d-none');
    }   
}

async function deleteImageSpaceUser(user: User) {
    var userDoc = doc(collection(firebaseDb, 'users'), user.uid);
    var docSnap = await getDoc(userDoc);

    // Delete the scans belonging to this user.
    if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.scans && (data.scans.length > 0)) {
            for (const scan of data.scans) {
                var r = ref(firebaseStorage, `scans/${scan}`);
                await deleteObject(r);
            };
        }
    }

    // Delete user Doc
    await deleteDoc(userDoc);

    // Delete the user from Firebase Auth.
    await deleteUser(user);
}