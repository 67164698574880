// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Annotation, AnnotationT } from '../fbfloorplanner/annotation.js';


export class NewAnnotation implements flatbuffers.IUnpackableObject<NewAnnotationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NewAnnotation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsNewAnnotation(bb:flatbuffers.ByteBuffer, obj?:NewAnnotation):NewAnnotation {
  return (obj || new NewAnnotation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsNewAnnotation(bb:flatbuffers.ByteBuffer, obj?:NewAnnotation):NewAnnotation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new NewAnnotation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

annotation(obj?:Annotation):Annotation|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Annotation()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startNewAnnotation(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addAnnotation(builder:flatbuffers.Builder, annotationOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, annotationOffset, 0);
}

static endNewAnnotation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createNewAnnotation(builder:flatbuffers.Builder, annotationOffset:flatbuffers.Offset):flatbuffers.Offset {
  NewAnnotation.startNewAnnotation(builder);
  NewAnnotation.addAnnotation(builder, annotationOffset);
  return NewAnnotation.endNewAnnotation(builder);
}

unpack(): NewAnnotationT {
  return new NewAnnotationT(
    (this.annotation() !== null ? this.annotation()!.unpack() : null)
  );
}


unpackTo(_o: NewAnnotationT): void {
  _o.annotation = (this.annotation() !== null ? this.annotation()!.unpack() : null);
}
}

export class NewAnnotationT implements flatbuffers.IGeneratedObject {
constructor(
  public annotation: AnnotationT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const annotation = (this.annotation !== null ? this.annotation!.pack(builder) : 0);

  return NewAnnotation.createNewAnnotation(builder,
    annotation
  );
}
}
