// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum RegionType {
  Other = 0,
  Deck = 1,
  Patio = 2,
  Porch = 3,
  Pool = 4,
  Shed = 5,
  NonGLA = 6
}
