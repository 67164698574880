// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Annotations, AnnotationsT } from '../fbfloorplanner/annotations.js';


export class HighlightAnnotation implements flatbuffers.IUnpackableObject<HighlightAnnotationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):HighlightAnnotation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsHighlightAnnotation(bb:flatbuffers.ByteBuffer, obj?:HighlightAnnotation):HighlightAnnotation {
  return (obj || new HighlightAnnotation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsHighlightAnnotation(bb:flatbuffers.ByteBuffer, obj?:HighlightAnnotation):HighlightAnnotation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new HighlightAnnotation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

annotations(obj?:Annotations):Annotations|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Annotations()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startHighlightAnnotation(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addAnnotations(builder:flatbuffers.Builder, annotationsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, annotationsOffset, 0);
}

static endHighlightAnnotation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createHighlightAnnotation(builder:flatbuffers.Builder, annotationsOffset:flatbuffers.Offset):flatbuffers.Offset {
  HighlightAnnotation.startHighlightAnnotation(builder);
  HighlightAnnotation.addAnnotations(builder, annotationsOffset);
  return HighlightAnnotation.endHighlightAnnotation(builder);
}

unpack(): HighlightAnnotationT {
  return new HighlightAnnotationT(
    (this.annotations() !== null ? this.annotations()!.unpack() : null)
  );
}


unpackTo(_o: HighlightAnnotationT): void {
  _o.annotations = (this.annotations() !== null ? this.annotations()!.unpack() : null);
}
}

export class HighlightAnnotationT implements flatbuffers.IGeneratedObject {
constructor(
  public annotations: AnnotationsT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const annotations = (this.annotations !== null ? this.annotations!.pack(builder) : 0);

  return HighlightAnnotation.createHighlightAnnotation(builder,
    annotations
  );
}
}
