// ImageSpace main entry point.
import { firebaseApp } from './firebase/_app';
import { setupAuth } from './firebase/_auth';
import { Tooltip, Toast, Popover, Carousel, Modal } from 'bootstrap';
import { firebaseStorage } from './firebase/_storage'
import { User } from 'firebase/auth'
import { getStorage, ref, getBytes, getDownloadURL } from "firebase/storage"
import { collection, doc, getDoc, setDoc, DocumentSnapshot } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { firebaseDb } from './firebase/_database'
import { transition, isMobile, setText, hideModal, isLocalHost, isLog, showError } from './_utils'
import { Scan, ScanT } from 'src/flatbuffers/fbfloorplanner/scan'
import { ToCpp, ToCppT } from 'src/flatbuffers/fbfloorplanner/to-cpp'
import { FromCpp, FromCppT } from 'src/flatbuffers/fbfloorplanner/from-cpp'
import { SurfaceAction } from 'src/flatbuffers/fbfloorplanner/surface-action'
import { SurfaceMessageT } from 'src/flatbuffers/fbfloorplanner/surface-message'
import { ViewportChangeT } from 'src/flatbuffers/fbfloorplanner/viewport-change'
import { Viewport } from 'src/flatbuffers/fbfloorplanner/viewport'
import { Perspective } from 'src/flatbuffers/fbfloorplanner/perspective'
import { Model } from 'src/flatbuffers/fbfloorplanner/model'
import { Snapshot  } from './flatbuffers/fbfloorplanner/snapshot'
import {} from "firebase/firestore"
import * as flatbuffers from 'flatbuffers';
import { FirebaseError } from 'firebase/app'

import { IncomingMessage } from 'src/flatbuffers/fbfloorplanner/incoming-message'
import { OutgoingMessage } from 'src/flatbuffers/fbfloorplanner/outgoing-message'
import { NewAnnotationT } from 'src/flatbuffers/fbfloorplanner/new-annotation'
import { NewMeasurementT } from 'src/flatbuffers/fbfloorplanner/new-measurement'

import { PendingTexture, Viewer } from './_nativeView'
import { Main, switchTo } from './_states'
import { user, addUserChangeHandler } from './firebase/_auth'

import { addSnapshot, showSnapshotImage, snapshotCard } from './_snapshots';

export function loadSnapshot(scan: string, snapshot: string) {

    // Set the card.
    (document.getElementById('snapshotImage') as HTMLDivElement).innerHTML = snapshotCard(scan, snapshot,false);

    addSnapshot(scan, snapshot);

}