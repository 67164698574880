// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { DecodedFlatbuffer, DecodedFlatbufferT } from '../fbfloorplanner/decoded-flatbuffer.js';
import { NewAnnotation, NewAnnotationT } from '../fbfloorplanner/new-annotation.js';
import { NewMeasurement, NewMeasurementT } from '../fbfloorplanner/new-measurement.js';


export enum OutgoingMessage {
  NONE = 0,
  NewAnnotation = 1,
  NewMeasurement = 2,
  DecodedFlatbuffer = 3
}

export function unionToOutgoingMessage(
  type: OutgoingMessage,
  accessor: (obj:DecodedFlatbuffer|NewAnnotation|NewMeasurement) => DecodedFlatbuffer|NewAnnotation|NewMeasurement|null
): DecodedFlatbuffer|NewAnnotation|NewMeasurement|null {
  switch(OutgoingMessage[type]) {
    case 'NONE': return null; 
    case 'NewAnnotation': return accessor(new NewAnnotation())! as NewAnnotation;
    case 'NewMeasurement': return accessor(new NewMeasurement())! as NewMeasurement;
    case 'DecodedFlatbuffer': return accessor(new DecodedFlatbuffer())! as DecodedFlatbuffer;
    default: return null;
  }
}

export function unionListToOutgoingMessage(
  type: OutgoingMessage, 
  accessor: (index: number, obj:DecodedFlatbuffer|NewAnnotation|NewMeasurement) => DecodedFlatbuffer|NewAnnotation|NewMeasurement|null, 
  index: number
): DecodedFlatbuffer|NewAnnotation|NewMeasurement|null {
  switch(OutgoingMessage[type]) {
    case 'NONE': return null; 
    case 'NewAnnotation': return accessor(index, new NewAnnotation())! as NewAnnotation;
    case 'NewMeasurement': return accessor(index, new NewMeasurement())! as NewMeasurement;
    case 'DecodedFlatbuffer': return accessor(index, new DecodedFlatbuffer())! as DecodedFlatbuffer;
    default: return null;
  }
}
