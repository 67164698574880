// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Annotation, AnnotationT } from '../fbfloorplanner/annotation.js';
import { Level, LevelT } from '../fbfloorplanner/level.js';
import { Measurement, MeasurementT } from '../fbfloorplanner/measurement.js';


export class Annotations implements flatbuffers.IUnpackableObject<AnnotationsT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Annotations {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsAnnotations(bb:flatbuffers.ByteBuffer, obj?:Annotations):Annotations {
  return (obj || new Annotations()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsAnnotations(bb:flatbuffers.ByteBuffer, obj?:Annotations):Annotations {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Annotations()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

levels(index: number, obj?:Level):Level|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Level()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

levelsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

annotations(index: number, obj?:Annotation):Annotation|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new Annotation()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

annotationsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

measurements(index: number, obj?:Measurement):Measurement|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new Measurement()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

measurementsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startAnnotations(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(0, version, 0);
}

static addLevels(builder:flatbuffers.Builder, levelsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, levelsOffset, 0);
}

static createLevelsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startLevelsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addAnnotations(builder:flatbuffers.Builder, annotationsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, annotationsOffset, 0);
}

static createAnnotationsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startAnnotationsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addMeasurements(builder:flatbuffers.Builder, measurementsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, measurementsOffset, 0);
}

static createMeasurementsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startMeasurementsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endAnnotations(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createAnnotations(builder:flatbuffers.Builder, version:number, levelsOffset:flatbuffers.Offset, annotationsOffset:flatbuffers.Offset, measurementsOffset:flatbuffers.Offset):flatbuffers.Offset {
  Annotations.startAnnotations(builder);
  Annotations.addVersion(builder, version);
  Annotations.addLevels(builder, levelsOffset);
  Annotations.addAnnotations(builder, annotationsOffset);
  Annotations.addMeasurements(builder, measurementsOffset);
  return Annotations.endAnnotations(builder);
}

unpack(): AnnotationsT {
  return new AnnotationsT(
    this.version(),
    this.bb!.createObjList<Level, LevelT>(this.levels.bind(this), this.levelsLength()),
    this.bb!.createObjList<Annotation, AnnotationT>(this.annotations.bind(this), this.annotationsLength()),
    this.bb!.createObjList<Measurement, MeasurementT>(this.measurements.bind(this), this.measurementsLength())
  );
}


unpackTo(_o: AnnotationsT): void {
  _o.version = this.version();
  _o.levels = this.bb!.createObjList<Level, LevelT>(this.levels.bind(this), this.levelsLength());
  _o.annotations = this.bb!.createObjList<Annotation, AnnotationT>(this.annotations.bind(this), this.annotationsLength());
  _o.measurements = this.bb!.createObjList<Measurement, MeasurementT>(this.measurements.bind(this), this.measurementsLength());
}
}

export class AnnotationsT implements flatbuffers.IGeneratedObject {
constructor(
  public version: number = 0,
  public levels: (LevelT)[] = [],
  public annotations: (AnnotationT)[] = [],
  public measurements: (MeasurementT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const levels = Annotations.createLevelsVector(builder, builder.createObjectOffsetList(this.levels));
  const annotations = Annotations.createAnnotationsVector(builder, builder.createObjectOffsetList(this.annotations));
  const measurements = Annotations.createMeasurementsVector(builder, builder.createObjectOffsetList(this.measurements));

  return Annotations.createAnnotations(builder,
    this.version,
    levels,
    annotations,
    measurements
  );
}
}
