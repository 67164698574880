// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum Viewport {
  TopDown = 0,
  Sideways = 1,
  Canonical = 2,
  Same = 3
}
