// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Box2, Box2T } from '../fbmath/box2.js';
import { TRXform, TRXformT } from '../fbmath/trxform.js';


export class Fragment implements flatbuffers.IUnpackableObject<FragmentT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Fragment {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

xform(obj?:TRXform):TRXform|null {
  return (obj || new TRXform()).__init(this.bb_pos, this.bb!);
}

bounds(obj?:Box2):Box2|null {
  return (obj || new Box2()).__init(this.bb_pos + 28, this.bb!);
}

weight():number {
  return this.bb!.readFloat32(this.bb_pos + 44);
}

static sizeOf():number {
  return 48;
}

static createFragment(builder:flatbuffers.Builder, xform_translation_x: number, xform_translation_y: number, xform_translation_z: number, xform_orientation_x: number, xform_orientation_y: number, xform_orientation_z: number, xform_orientation_w: number, bounds_min_x: number, bounds_min_y: number, bounds_max_x: number, bounds_max_y: number, weight: number):flatbuffers.Offset {
  builder.prep(4, 48);
  builder.writeFloat32(weight);
  builder.prep(4, 16);
  builder.prep(4, 8);
  builder.writeFloat32(bounds_max_y);
  builder.writeFloat32(bounds_max_x);
  builder.prep(4, 8);
  builder.writeFloat32(bounds_min_y);
  builder.writeFloat32(bounds_min_x);
  builder.prep(4, 28);
  builder.prep(4, 16);
  builder.writeFloat32(xform_orientation_w);
  builder.writeFloat32(xform_orientation_z);
  builder.writeFloat32(xform_orientation_y);
  builder.writeFloat32(xform_orientation_x);
  builder.prep(4, 12);
  builder.writeFloat32(xform_translation_z);
  builder.writeFloat32(xform_translation_y);
  builder.writeFloat32(xform_translation_x);
  return builder.offset();
}


unpack(): FragmentT {
  return new FragmentT(
    (this.xform() !== null ? this.xform()!.unpack() : null),
    (this.bounds() !== null ? this.bounds()!.unpack() : null),
    this.weight()
  );
}


unpackTo(_o: FragmentT): void {
  _o.xform = (this.xform() !== null ? this.xform()!.unpack() : null);
  _o.bounds = (this.bounds() !== null ? this.bounds()!.unpack() : null);
  _o.weight = this.weight();
}
}

export class FragmentT implements flatbuffers.IGeneratedObject {
constructor(
  public xform: TRXformT|null = null,
  public bounds: Box2T|null = null,
  public weight: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Fragment.createFragment(builder,
    (this.xform?.translation?.x ?? 0),
    (this.xform?.translation?.y ?? 0),
    (this.xform?.translation?.z ?? 0),
    (this.xform?.orientation?.x ?? 0),
    (this.xform?.orientation?.y ?? 0),
    (this.xform?.orientation?.z ?? 0),
    (this.xform?.orientation?.w ?? 0),
    (this.bounds?.min?.x ?? 0),
    (this.bounds?.min?.y ?? 0),
    (this.bounds?.max?.x ?? 0),
    (this.bounds?.max?.y ?? 0),
    this.weight
  );
}
}
