// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Vector3, Vector3T } from '../fbmath/vector3.js';


export class Corner implements flatbuffers.IUnpackableObject<CornerT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Corner {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

position(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos, this.bb!);
}

fragment0():number {
  return this.bb!.readUint16(this.bb_pos + 12);
}

fragment1():number {
  return this.bb!.readUint16(this.bb_pos + 14);
}

fragment2():number {
  return this.bb!.readUint16(this.bb_pos + 16);
}

static sizeOf():number {
  return 20;
}

static createCorner(builder:flatbuffers.Builder, position_x: number, position_y: number, position_z: number, fragment0: number, fragment1: number, fragment2: number):flatbuffers.Offset {
  builder.prep(4, 20);
  builder.pad(2);
  builder.writeInt16(fragment2);
  builder.writeInt16(fragment1);
  builder.writeInt16(fragment0);
  builder.prep(4, 12);
  builder.writeFloat32(position_z);
  builder.writeFloat32(position_y);
  builder.writeFloat32(position_x);
  return builder.offset();
}


unpack(): CornerT {
  return new CornerT(
    (this.position() !== null ? this.position()!.unpack() : null),
    this.fragment0(),
    this.fragment1(),
    this.fragment2()
  );
}


unpackTo(_o: CornerT): void {
  _o.position = (this.position() !== null ? this.position()!.unpack() : null);
  _o.fragment0 = this.fragment0();
  _o.fragment1 = this.fragment1();
  _o.fragment2 = this.fragment2();
}
}

export class CornerT implements flatbuffers.IGeneratedObject {
constructor(
  public position: Vector3T|null = null,
  public fragment0: number = 0,
  public fragment1: number = 0,
  public fragment2: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Corner.createCorner(builder,
    (this.position?.x ?? 0),
    (this.position?.y ?? 0),
    (this.position?.z ?? 0),
    this.fragment0,
    this.fragment1,
    this.fragment2
  );
}
}
