import { firebaseStorage } from './_storage'
import { User } from 'firebase/auth'
import { Modal } from 'bootstrap'
import { getStorage, ref, getBytes, getDownloadURL } from "firebase/storage"
import { collection, doc, getDoc, setDoc, DocumentSnapshot } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { firebaseDb } from './_database'
import { setText, showModal, hideModal, isLocalHost, isLog } from '../_utils'
import { Scan, ScanT } from '../flatbuffers/fbfloorplanner/scan'
import { ToCpp, ToCppT } from '../flatbuffers/fbfloorplanner/to-cpp'
import { ViewportChangeT } from '../flatbuffers/fbfloorplanner/viewport-change'
import { Viewport } from '../flatbuffers/fbfloorplanner/viewport'
import { Perspective } from '../flatbuffers/fbfloorplanner/perspective'
import { Model } from '../flatbuffers/fbfloorplanner/model'
import {} from "firebase/firestore"
import * as flatbuffers from 'flatbuffers';
import { FirebaseError } from 'firebase/app'

// A texture waiting for be loaded.
type PendingTexture = {
    atlasIndex: number;
    url: string;
};

// These must match the flags in modelview.hpp
enum Flags {
    kIsPositionSnappinEnabled    = 1 << 1,
    kIsOrientationSnappinEnabled = 1 << 2,
    kIsMetric                    = 1 << 3,
    kDetectPlanes                = 1 << 4,
    kShowModelFront              = 1 << 5,

    kDemoMode = 1 << 7,

    kRotationLocked    = 1 << 8,
    kTranslationLocked = 1 << 9,

    kCropping = 1 << 13,
    kSlicing  = 1 << 14,
    kFraming  = 1 << 15,

    kAnnotating        = 1 << 16,
    kRotating          = 1 << 17,
    kMeasuring         = 1 << 18,
    kRecting           = 1 << 19,
    kRaycasting        = 1 << 25,
    kMeasurementAdding = 1 << 26,

    kShowStructure = 1 << 20,

    kSlicingTop    = 1 << 21,
    kSlicingBottom = 1 << 22,

    kHighlightMeasurements = 1 << 23,
    kHighlightAnnotations  = 1 << 27,
    kShowingSlice          = 1 << 24,

    kCropInside = 1 << 30,
}

// Some global variables for the bridge between C++ and javascript.
var pendingBytesArray: ArrayBuffer[] = [];
var pendingTextures: PendingTexture[] = [];
var imageSpaceReady = false;
var context: WebGL2RenderingContext | null = null;
var flags: Flags = Flags.kIsMetric;

// These are exported from floorplanner/Web/post.js.
// These functions bridge C++ and javascript.
declare global {
    interface Window {
        imageSpaceReady: boolean|null;
        setImageSpaceData:(data: ArrayBuffer) => ArrayBuffer
        getContext:() => WebGL2RenderingContext|null
        setTexture:(atlasIndex: number, texture: WebGLTexture, width: number, height: number) => void
        setOnModuleReadyCallback:(callback: () => void) => void
        setFlags:(flags: number) => void
        center:(animated: boolean) => void
        onImageSpaceReady: () => void;
    }
}



// Add this function
function createTextureFromURL(gl: WebGL2RenderingContext, atlasIndex: number, url: string) {
    const image = new Image();
    image.crossOrigin = "anonymous"; // Enable CORS if the image is from a different domain

    image.onload = function() {
        const texture = gl.createTexture();
        if (texture) {
            gl.bindTexture(gl.TEXTURE_2D, texture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.SRGB8_ALPHA8, gl.RGBA, gl.UNSIGNED_BYTE, image);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
            window.setTexture(atlasIndex, texture, image.width, image.height);
        } else {
            console.error("Error creating texture for atlas", atlasIndex);
        }
    };

    image.onerror = function() {
        console.error("Error loading texture:", url);
    }

    // Set the src after defining onload and onerror
    image.src = url;
}

// Assign the function to the window object
window.onImageSpaceReady = () => {
    isLog('Module is ready');
    imageSpaceReady = true;

    context = window.getContext();
    if (context == null) {
        console.error('No context found');
        return;
    }

    for (const bytes of pendingBytesArray) {
        window.setImageSpaceData(bytes);
    }

    if (context) {
        for (const texture of pendingTextures) {
            createTextureFromURL(context, texture.atlasIndex, texture.url);
        }
    }

    pendingTextures = [];
    pendingBytesArray = [];
};

function dispatchFlatbuffer(bytes: ArrayBuffer) {
    if (imageSpaceReady) {
        window.setImageSpaceData(bytes);
    } else {
        pendingBytesArray.push(bytes);
    }
}

function dispatch(message: ToCppT) {
    var builder = new flatbuffers.Builder(1024);
    builder.finish(message.pack(builder), 'TOCP');
    dispatchFlatbuffer(builder.asUint8Array());
}

function dispatchTexture(atlasIndex: number, url: string) {
    if (imageSpaceReady) {
        if (context) {
            createTextureFromURL(context, atlasIndex, url);
        }
    } else {

        // Remove the existing pending texture for this atlas index.
        pendingTextures = pendingTextures.filter(texture => texture.atlasIndex != atlasIndex);

        // Add the new pending texture.
        pendingTextures.push({
            atlasIndex,
            url
        });
    }
}

function showError(error: FirebaseError) {
    var element = document.getElementById('errorModal');
    if (element) {
        var errorModal = new Modal(element);
        setText('errorMessage', error.message);
        errorModal.show();
    }
}


// Called when the hover ID changes.
function onHoverIdChanged(newId: number) {
}

// Called when the flags change.
function onOutFlagsChanged(newFlags: number) {
}

function setupViewerMenu() {
    document.getElementById('scanHelpButton')?.addEventListener('click', () => {
        showModal('helpModal');
    });
    document.getElementById('scanCenterButton')?.addEventListener('click', () => {
        window.center(true);
    });

    document.getElementById('scanOrthoButton')?.addEventListener('click', () => {
        var toCpp = new ToCppT();
        toCpp.message = new ViewportChangeT();
        toCpp.message.viewport = Viewport.TopDown;
        toCpp.message.perspective = Perspective.Orthographic;
        dispatch(toCpp);
    });

    document.getElementById('scanPerspectiveButton')?.addEventListener('click', () => {
        var toCpp = new ToCppT();
        toCpp.message = new ViewportChangeT();
        toCpp.message.viewport = Viewport.Canonical;
        toCpp.message.perspective = Perspective.Perspective;
        dispatch(toCpp);
    });   
}

  
function mapUrlForLatLong(latitude: number, longitude: number, address: string | null) {
    return "https://www.google.com/maps/search/?api=1&query=" + latitude + "," + longitude;
}   

function updateMetaProperty(property: string, value: string) {
    const metaTag = document.querySelector(`meta[property="${property}"]`);
    if (metaTag) {
        metaTag.setAttribute('content', value);
    }
}

function textureSuffixForLevel(level: number) {
    if (level == 0) {
        return '';
    } else if (level == 1) {
        return '_2';
    } else if (level == 2) {
        return '_4';
    } else {
        return '_8';
    }
}

async function loadScanData(scanId: string, scanData: DocumentSnapshot) {

    // Fetch the data.
    const data = scanData.data();
    if (data == null) {
        return;
    }

    // Setup the menu.
    setupViewerMenu();

    // Update the meta tags with the scan data.
    updateMetaProperty('description', data['title'] ?? data['address'] ?? "3D scan created by the ImageSpace mobile app.");
    updateMetaProperty('og:description', data['title'] ?? data['address'] ?? "3D scan created by the ImageSpace mobile app.");

    // Figure out the title for the scan.
    var title = data['title'];

    // Format the address line.
    if ((data['latitude'] as number != 0) && (data['longitude'] as number != 0)) {
        let url = mapUrlForLatLong(data['latitude'] as number, data['longitude'] as number, data['address'] as string);

        title = title ?? (data['address'] as string)?.split('\n')[0] ?? "Untitled scan";

        title = "<a href=" + url + " target=\"_blank\"><i class=\"bi bi-pin-map\"></i></a> " + title;
    } else {
        document.getElementById('scanAddress')?.classList.add('d-none');
    }

    // Set the title.
    setText('scanTitle', title ?? "Untitled scan");

    // Setup the date time.
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    setText('scanDateTime', data['timestamp'].toDate().toLocaleString(undefined, options));
    document.getElementById('scanDetails')?.classList.remove('d-none');
    
    // If the WASM module was initialized we need to call imageSpaceManually.
    if ((window.imageSpaceReady == true) && (imageSpaceReady == false)) {
        window.onImageSpaceReady();
    }

    // Figure out how mant atlass pages we have
    const atlasCount = data['numAtlasPages'] ?? 0;
    if (atlasCount == 0) {
        console.error("No atlas pages found for scan " + scanId);
        return;
    }

    const numPixels = data['numAtlasPages'] * data['atlasResolution'] * data['atlasResolution'];
    const maxPixels = 10000000; // This is the maximum number of pixels we will load.
    // numPixels * (0.25)^n = maxPixels
    // (0.25)^n = maxPixels / numPixels
    // n = log(maxPixels / numPixels) / log(0.25)
    const n = Math.log(maxPixels / numPixels) / Math.log(0.25);
    const level = Math.ceil(Math.min(3, n));
    const suffix = textureSuffixForLevel(level);
    isLog('Max level is ' + level + ', suffix ' + suffix);
    
    // Function to load textures
    const loadTextures = (resolution: string) => {
        const promises = [];
        for (let atlasIndex = 0; atlasIndex < atlasCount; atlasIndex++) {
            const fileName = `scans/${scanId}/Textures/atlas_${atlasIndex}${resolution}.webp`;
            const promise = getDownloadURL(ref(firebaseStorage, fileName))
                .then(webpURL => {
                    dispatchTexture(atlasIndex, webpURL);
                })
                .catch(error => {
                    console.error(`Error loading ${resolution === '_4' ? 'low-res' : 'high-res'} texture for atlas ${atlasIndex}:`, error);
                    // Attempt to load a placeholder texture or skip this texture
                    return Promise.resolve();

                });
            promises.push(promise);
        }
        return Promise.all(promises);
    };

    // Load low resolution textures first, then high resolution
    loadTextures('_8')
        .then(() => {
            isLog("Low-res textures loaded. Waiting 1 second before loading high-res textures...");
            document.getElementById('loadingSpinner')?.classList.add('d-none');
            return new Promise(resolve => setTimeout(resolve, 1000));
        })
        .then(() => {
            if (suffix != '_8') {
                isLog("Loading high-res textures...");
                return loadTextures(suffix);
            }
        })
        .then(() => {
            isLog("All textures loaded successfully.");
        })
        .catch(error => {
            console.error("Error in texture loading process:", error);
        });

    // Load and dispatch the flatbuffers.
    var flatbuffers =[ "model.fb", "structure.fb", "annotations.fb", "scan.fb"];
    for (const flatbuffer of flatbuffers) {
        getBytes(ref(firebaseStorage, 'scans/' + scanId + '/' + flatbuffer)).then((bytes: ArrayBuffer) => {
            dispatchFlatbuffer(bytes);
        })
        .catch((error) => {
            //showError(error);
            isLog(error);
        });
    }
}


async function checkProgress(scanId: string, modal: Modal|null) {
    const scanDoc = await getDoc(doc(firebaseDb, 'scans', scanId));
    if (scanDoc.exists() && ('processedAt' in scanDoc.data())) {
        // Kill the modal if we are showing it.
        modal?.hide();

        // Read to download the scan data.
        loadScanData(scanId, scanDoc);
    } else {
        if (modal == null) {
            var element = document.getElementById('processingModal');
            if (element) {
                modal = new Modal(element);
                modal.show();
            }
        }

        // Wait for 2 seconds and check again.
        setTimeout(async () => {
            await checkProgress(scanId, modal);
        }, 2000);
    }
}

export async function loadScan(scanId: string) {
    isLog("Loading scan " + scanId);

    getDownloadURL(ref(firebaseStorage, "scans/" + scanId + "/thumbnail_4.webp"))
            .then((url) => {
                updateMetaProperty('og:image', url);
            })
            .catch((error) => {
                isLog("Scan thumbnail was not found:", error);
            });
    
    // Get the scans from the database.
    getDoc(doc(firebaseDb, 'scans', scanId)).then((scanDoc) => {
        if (scanDoc.exists()) {

            // Is the scan processed already?
            if ('processedAt' in scanDoc.data()) {
                loadScanData(scanId, scanDoc);
            } else {
                checkProgress(scanId, null);
            }

            // Get the user profile image.
            getDownloadURL(ref(firebaseStorage, "users/" + scanDoc.data()['uid'] + "/profile_4.webp"))
            .then((url) => {
                setText('scanUserIcon', url);
                document.querySelectorAll('.scanUserIcon').forEach(element => {
                    element.classList.remove('d-none');
                });
            })
            .catch((error) => {
                isLog("User profile image not found:", error);
            });

            // Get the user data.
            getDoc(doc(firebaseDb, 'users', scanDoc.data()['uid'])).then((userDoc) => {
                if (userDoc.exists()) {
                    setText("scanUserName", userDoc.data()['displayName']);
                }
            });
        } else {
            // Redirect to 404
            window.location.href = "/404.html";
        }
    });

}

export function updateScans(user: User | null) {
    /*
    if (user != null) {
        var db = firebase.firestore();
        var scansCollection = db.collection("users").doc(user.uid).collection("scans");
        scansCollection.get().then((querySnapshot) => {
            var innerHtml = '';
            var storage = firebase.storage();
            var modelsRef = storage.ref('models');
            querySnapshot.forEach((doc) => {
                var scanRef = modelsRef.child(doc.id);
                var thumbRef = scanRef.child('thumb.jpg');
                thumbRef.getDownloadURL().then((url) => {
                    $("#" + doc.id).html('<img src="' + url + '" />');
                });
                innerHtml += '<div class="modelThumbCard"><a id="' + doc.id + '" href="#" class="modelThumb"></a>';
                innerHtml += '<span>' + doc.data().UploadDateTime + '</span>';
                innerHtml += '</a></div>';
            });
            setScansHtml(innerHtml);

            $('.modelThumb').on("click", function () {
                loadModel($(this).attr("id") ?? "");
                hideScans();
            });

        }).catch((error: any) => {
            console.log("Error getting cached document:", error);
        });


    } else {

        setScansHtml('');
    }
    */
}

// export function initScans() {
//     hideScans();
// }