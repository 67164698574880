// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { MeasurementAction } from '../fbfloorplanner/measurement-action.js';


export class MeasurementMessage implements flatbuffers.IUnpackableObject<MeasurementMessageT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):MeasurementMessage {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsMeasurementMessage(bb:flatbuffers.ByteBuffer, obj?:MeasurementMessage):MeasurementMessage {
  return (obj || new MeasurementMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsMeasurementMessage(bb:flatbuffers.ByteBuffer, obj?:MeasurementMessage):MeasurementMessage {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new MeasurementMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

action():MeasurementAction {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : MeasurementAction.SaveMeasurement;
}

static startMeasurementMessage(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addAction(builder:flatbuffers.Builder, action:MeasurementAction) {
  builder.addFieldInt8(0, action, MeasurementAction.SaveMeasurement);
}

static endMeasurementMessage(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createMeasurementMessage(builder:flatbuffers.Builder, action:MeasurementAction):flatbuffers.Offset {
  MeasurementMessage.startMeasurementMessage(builder);
  MeasurementMessage.addAction(builder, action);
  return MeasurementMessage.endMeasurementMessage(builder);
}

unpack(): MeasurementMessageT {
  return new MeasurementMessageT(
    this.action()
  );
}


unpackTo(_o: MeasurementMessageT): void {
  _o.action = this.action();
}
}

export class MeasurementMessageT implements flatbuffers.IGeneratedObject {
constructor(
  public action: MeasurementAction = MeasurementAction.SaveMeasurement
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return MeasurementMessage.createMeasurementMessage(builder,
    this.action
  );
}
}
