// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Measurement, MeasurementT } from '../fbfloorplanner/measurement.js';


export class NewMeasurement implements flatbuffers.IUnpackableObject<NewMeasurementT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NewMeasurement {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsNewMeasurement(bb:flatbuffers.ByteBuffer, obj?:NewMeasurement):NewMeasurement {
  return (obj || new NewMeasurement()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsNewMeasurement(bb:flatbuffers.ByteBuffer, obj?:NewMeasurement):NewMeasurement {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new NewMeasurement()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

measurement(obj?:Measurement):Measurement|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Measurement()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startNewMeasurement(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addMeasurement(builder:flatbuffers.Builder, measurementOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, measurementOffset, 0);
}

static endNewMeasurement(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createNewMeasurement(builder:flatbuffers.Builder, measurementOffset:flatbuffers.Offset):flatbuffers.Offset {
  NewMeasurement.startNewMeasurement(builder);
  NewMeasurement.addMeasurement(builder, measurementOffset);
  return NewMeasurement.endNewMeasurement(builder);
}

unpack(): NewMeasurementT {
  return new NewMeasurementT(
    (this.measurement() !== null ? this.measurement()!.unpack() : null)
  );
}


unpackTo(_o: NewMeasurementT): void {
  _o.measurement = (this.measurement() !== null ? this.measurement()!.unpack() : null);
}
}

export class NewMeasurementT implements flatbuffers.IGeneratedObject {
constructor(
  public measurement: MeasurementT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const measurement = (this.measurement !== null ? this.measurement!.pack(builder) : 0);

  return NewMeasurement.createNewMeasurement(builder,
    measurement
  );
}
}
