// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { ModelSlice, ModelSliceT } from '../fbfloorplanner/model-slice.js';


export class Measurement implements flatbuffers.IUnpackableObject<MeasurementT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Measurement {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsMeasurement(bb:flatbuffers.ByteBuffer, obj?:Measurement):Measurement {
  return (obj || new Measurement()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsMeasurement(bb:flatbuffers.ByteBuffer, obj?:Measurement):Measurement {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Measurement()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

title():string|null
title(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
title(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

slice(obj?:ModelSlice):ModelSlice|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ModelSlice()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

vertices(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

verticesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

verticesArray():Float32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Float32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startMeasurement(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addTitle(builder:flatbuffers.Builder, titleOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, titleOffset, 0);
}

static addSlice(builder:flatbuffers.Builder, sliceOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, sliceOffset, 0);
}

static addVertices(builder:flatbuffers.Builder, verticesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, verticesOffset, 0);
}

static createVerticesVector(builder:flatbuffers.Builder, data:number[]|Float32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createVerticesVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createVerticesVector(builder:flatbuffers.Builder, data:number[]|Float32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat32(data[i]!);
  }
  return builder.endVector();
}

static startVerticesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endMeasurement(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): MeasurementT {
  return new MeasurementT(
    this.title(),
    (this.slice() !== null ? this.slice()!.unpack() : null),
    this.bb!.createScalarList<number>(this.vertices.bind(this), this.verticesLength())
  );
}


unpackTo(_o: MeasurementT): void {
  _o.title = this.title();
  _o.slice = (this.slice() !== null ? this.slice()!.unpack() : null);
  _o.vertices = this.bb!.createScalarList<number>(this.vertices.bind(this), this.verticesLength());
}
}

export class MeasurementT implements flatbuffers.IGeneratedObject {
constructor(
  public title: string|Uint8Array|null = null,
  public slice: ModelSliceT|null = null,
  public vertices: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const title = (this.title !== null ? builder.createString(this.title!) : 0);
  const slice = (this.slice !== null ? this.slice!.pack(builder) : 0);
  const vertices = Measurement.createVerticesVector(builder, this.vertices);

  Measurement.startMeasurement(builder);
  Measurement.addTitle(builder, title);
  Measurement.addSlice(builder, slice);
  Measurement.addVertices(builder, vertices);

  return Measurement.endMeasurement(builder);
}
}
