// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum SurfaceAction {
  SelectAnnotationPlane = 0,
  SelectMeasurePlane = 1,
  ReleaseAnnotationPlane = 2,
  AdditiveStart = 3,
  SubtractiveStart = 4,
  Commit = 5,
  Cancel = 6
}
