// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Box2, Box2T } from '../fbmath/box2.js';
import { TRXform, TRXformT } from '../fbmath/trxform.js';


export class Camera implements flatbuffers.IUnpackableObject<CameraT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Camera {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCamera(bb:flatbuffers.ByteBuffer, obj?:Camera):Camera {
  return (obj || new Camera()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCamera(bb:flatbuffers.ByteBuffer, obj?:Camera):Camera {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Camera()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

fov(obj?:Box2):Box2|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Box2()).__init(this.bb_pos + offset, this.bb!) : null;
}

xform(obj?:TRXform):TRXform|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new TRXform()).__init(this.bb_pos + offset, this.bb!) : null;
}

perspective():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : true;
}

static startCamera(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addFov(builder:flatbuffers.Builder, fovOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, fovOffset, 0);
}

static addXform(builder:flatbuffers.Builder, xformOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, xformOffset, 0);
}

static addPerspective(builder:flatbuffers.Builder, perspective:boolean) {
  builder.addFieldInt8(2, +perspective, +true);
}

static endCamera(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): CameraT {
  return new CameraT(
    (this.fov() !== null ? this.fov()!.unpack() : null),
    (this.xform() !== null ? this.xform()!.unpack() : null),
    this.perspective()
  );
}


unpackTo(_o: CameraT): void {
  _o.fov = (this.fov() !== null ? this.fov()!.unpack() : null);
  _o.xform = (this.xform() !== null ? this.xform()!.unpack() : null);
  _o.perspective = this.perspective();
}
}

export class CameraT implements flatbuffers.IGeneratedObject {
constructor(
  public fov: Box2T|null = null,
  public xform: TRXformT|null = null,
  public perspective: boolean = true
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  Camera.startCamera(builder);
  Camera.addFov(builder, (this.fov !== null ? this.fov!.pack(builder) : 0));
  Camera.addXform(builder, (this.xform !== null ? this.xform!.pack(builder) : 0));
  Camera.addPerspective(builder, this.perspective);

  return Camera.endCamera(builder);
}
}
