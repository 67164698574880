// ImageSpace main entry point.
import { firebaseApp } from './firebase/_app';
import { firebaseDb } from './firebase/_database';
import { setupAuth } from './firebase/_auth';
import { doc, setDoc} from 'firebase/firestore';
import { Tooltip, Toast, Popover, Carousel, Modal } from 'bootstrap';
import { transition, isMobile } from './_utils';
import { loadScan } from './firebase/_scans';



export function setupViewer() {

    // Parse the query string.
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const scan = urlParams.get('s');
    if (scan) {
        loadScan(scan);

        if (isMobile.any()) {
            document.getElementById('desktopControls')?.classList.add('d-none');
        } else {
            document.getElementById('mobileControls')?.classList.add('d-none');
        }
    }
}


