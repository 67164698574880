// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Volume, VolumeT } from '../fbfloorplanner/volume.js';


export class PointCloud implements flatbuffers.IUnpackableObject<PointCloudT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PointCloud {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPointCloud(bb:flatbuffers.ByteBuffer, obj?:PointCloud):PointCloud {
  return (obj || new PointCloud()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPointCloud(bb:flatbuffers.ByteBuffer, obj?:PointCloud):PointCloud {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PointCloud()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static bufferHasIdentifier(bb:flatbuffers.ByteBuffer):boolean {
  return bb.__has_identifier('POCL');
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

volume(obj?:Volume):Volume|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Volume()).__init(this.bb_pos + offset, this.bb!) : null;
}

points():string|null
points(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
points(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startPointCloud(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(0, version, 0);
}

static addVolume(builder:flatbuffers.Builder, volumeOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, volumeOffset, 0);
}

static addPoints(builder:flatbuffers.Builder, pointsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, pointsOffset, 0);
}

static endPointCloud(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishPointCloudBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'POCL');
}

static finishSizePrefixedPointCloudBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'POCL', true);
}


unpack(): PointCloudT {
  return new PointCloudT(
    this.version(),
    (this.volume() !== null ? this.volume()!.unpack() : null),
    this.points()
  );
}


unpackTo(_o: PointCloudT): void {
  _o.version = this.version();
  _o.volume = (this.volume() !== null ? this.volume()!.unpack() : null);
  _o.points = this.points();
}
}

export class PointCloudT implements flatbuffers.IGeneratedObject {
constructor(
  public version: number = 0,
  public volume: VolumeT|null = null,
  public points: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const points = (this.points !== null ? builder.createString(this.points!) : 0);

  PointCloud.startPointCloud(builder);
  PointCloud.addVersion(builder, this.version);
  PointCloud.addVolume(builder, (this.volume !== null ? this.volume!.pack(builder) : 0));
  PointCloud.addPoints(builder, points);

  return PointCloud.endPointCloud(builder);
}
}
