// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Matrix3x3, Matrix3x3T } from '../fbmath/matrix3x3.js';
import { TRXform2D5, TRXform2D5T } from '../fbmath/trxform2-d5.js';
import { Vector3, Vector3T } from '../fbmath/vector3.js';
import { Camera, CameraT } from '../fbrendering/camera.js';


export class Frame implements flatbuffers.IUnpackableObject<FrameT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Frame {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFrame(bb:flatbuffers.ByteBuffer, obj?:Frame):Frame {
  return (obj || new Frame()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFrame(bb:flatbuffers.ByteBuffer, obj?:Frame):Frame {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Frame()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static bufferHasIdentifier(bb:flatbuffers.ByteBuffer):boolean {
  return bb.__has_identifier('FRAM');
}

timestampUs():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

camera(obj?:Camera):Camera|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Camera()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

colorWidth():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

colorHeight():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

depthWidth():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

depthHeight():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

flags():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

sessionIndex():number {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

trackIndex():number {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

intrinsics(obj?:Matrix3x3):Matrix3x3|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? (obj || new Matrix3x3()).__init(this.bb_pos + offset, this.bb!) : null;
}

cameraGrainIntensity():number {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

exposureDuration():number {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

exposureOffset():number {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

ambientIntensity():number {
  const offset = this.bb!.__offset(this.bb_pos, 30);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

ambientColorTemp():number {
  const offset = this.bb!.__offset(this.bb_pos, 32);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

brightness():number {
  const offset = this.bb!.__offset(this.bb_pos, 34);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

shutterSpeed():number {
  const offset = this.bb!.__offset(this.bb_pos, 36);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

aperture():number {
  const offset = this.bb!.__offset(this.bb_pos, 38);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

fNumber():number {
  const offset = this.bb!.__offset(this.bb_pos, 40);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

focalLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 42);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

focalLengthIn35():number {
  const offset = this.bb!.__offset(this.bb_pos, 44);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

optimizedXform(obj?:TRXform2D5):TRXform2D5|null {
  const offset = this.bb!.__offset(this.bb_pos, 46);
  return offset ? (obj || new TRXform2D5()).__init(this.bb_pos + offset, this.bb!) : null;
}

frameIndex():number {
  const offset = this.bb!.__offset(this.bb_pos, 48);
  return offset ? this.bb!.readInt32(this.bb_pos + offset) : -1;
}

linearVelocity(obj?:Vector3):Vector3|null {
  const offset = this.bb!.__offset(this.bb_pos, 50);
  return offset ? (obj || new Vector3()).__init(this.bb_pos + offset, this.bb!) : null;
}

angularVelocity(obj?:Vector3):Vector3|null {
  const offset = this.bb!.__offset(this.bb_pos, 52);
  return offset ? (obj || new Vector3()).__init(this.bb_pos + offset, this.bb!) : null;
}

startFeature():number {
  const offset = this.bb!.__offset(this.bb_pos, 54);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

numFeatures():number {
  const offset = this.bb!.__offset(this.bb_pos, 56);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

startFragment():number {
  const offset = this.bb!.__offset(this.bb_pos, 58);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

numFragments():number {
  const offset = this.bb!.__offset(this.bb_pos, 60);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

static startFrame(builder:flatbuffers.Builder) {
  builder.startObject(29);
}

static addTimestampUs(builder:flatbuffers.Builder, timestampUs:number) {
  builder.addFieldInt32(0, timestampUs, 0);
}

static addCamera(builder:flatbuffers.Builder, cameraOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, cameraOffset, 0);
}

static addColorWidth(builder:flatbuffers.Builder, colorWidth:number) {
  builder.addFieldInt16(2, colorWidth, 0);
}

static addColorHeight(builder:flatbuffers.Builder, colorHeight:number) {
  builder.addFieldInt16(3, colorHeight, 0);
}

static addDepthWidth(builder:flatbuffers.Builder, depthWidth:number) {
  builder.addFieldInt16(4, depthWidth, 0);
}

static addDepthHeight(builder:flatbuffers.Builder, depthHeight:number) {
  builder.addFieldInt16(5, depthHeight, 0);
}

static addFlags(builder:flatbuffers.Builder, flags:number) {
  builder.addFieldInt32(6, flags, 0);
}

static addSessionIndex(builder:flatbuffers.Builder, sessionIndex:number) {
  builder.addFieldInt16(7, sessionIndex, 0);
}

static addTrackIndex(builder:flatbuffers.Builder, trackIndex:number) {
  builder.addFieldInt16(8, trackIndex, 0);
}

static addIntrinsics(builder:flatbuffers.Builder, intrinsicsOffset:flatbuffers.Offset) {
  builder.addFieldStruct(9, intrinsicsOffset, 0);
}

static addCameraGrainIntensity(builder:flatbuffers.Builder, cameraGrainIntensity:number) {
  builder.addFieldFloat32(10, cameraGrainIntensity, 0.0);
}

static addExposureDuration(builder:flatbuffers.Builder, exposureDuration:number) {
  builder.addFieldFloat32(11, exposureDuration, 0.0);
}

static addExposureOffset(builder:flatbuffers.Builder, exposureOffset:number) {
  builder.addFieldFloat32(12, exposureOffset, 0.0);
}

static addAmbientIntensity(builder:flatbuffers.Builder, ambientIntensity:number) {
  builder.addFieldFloat32(13, ambientIntensity, 0.0);
}

static addAmbientColorTemp(builder:flatbuffers.Builder, ambientColorTemp:number) {
  builder.addFieldFloat32(14, ambientColorTemp, 0.0);
}

static addBrightness(builder:flatbuffers.Builder, brightness:number) {
  builder.addFieldFloat32(15, brightness, 0.0);
}

static addShutterSpeed(builder:flatbuffers.Builder, shutterSpeed:number) {
  builder.addFieldFloat32(16, shutterSpeed, 0.0);
}

static addAperture(builder:flatbuffers.Builder, aperture:number) {
  builder.addFieldFloat32(17, aperture, 0.0);
}

static addFNumber(builder:flatbuffers.Builder, fNumber:number) {
  builder.addFieldFloat32(18, fNumber, 0.0);
}

static addFocalLength(builder:flatbuffers.Builder, focalLength:number) {
  builder.addFieldFloat32(19, focalLength, 0.0);
}

static addFocalLengthIn35(builder:flatbuffers.Builder, focalLengthIn35:number) {
  builder.addFieldFloat32(20, focalLengthIn35, 0.0);
}

static addOptimizedXform(builder:flatbuffers.Builder, optimizedXformOffset:flatbuffers.Offset) {
  builder.addFieldStruct(21, optimizedXformOffset, 0);
}

static addFrameIndex(builder:flatbuffers.Builder, frameIndex:number) {
  builder.addFieldInt32(22, frameIndex, -1);
}

static addLinearVelocity(builder:flatbuffers.Builder, linearVelocityOffset:flatbuffers.Offset) {
  builder.addFieldStruct(23, linearVelocityOffset, 0);
}

static addAngularVelocity(builder:flatbuffers.Builder, angularVelocityOffset:flatbuffers.Offset) {
  builder.addFieldStruct(24, angularVelocityOffset, 0);
}

static addStartFeature(builder:flatbuffers.Builder, startFeature:number) {
  builder.addFieldInt32(25, startFeature, 0);
}

static addNumFeatures(builder:flatbuffers.Builder, numFeatures:number) {
  builder.addFieldInt16(26, numFeatures, 0);
}

static addStartFragment(builder:flatbuffers.Builder, startFragment:number) {
  builder.addFieldInt32(27, startFragment, 0);
}

static addNumFragments(builder:flatbuffers.Builder, numFragments:number) {
  builder.addFieldInt16(28, numFragments, 0);
}

static endFrame(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishFrameBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'FRAM');
}

static finishSizePrefixedFrameBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'FRAM', true);
}


unpack(): FrameT {
  return new FrameT(
    this.timestampUs(),
    (this.camera() !== null ? this.camera()!.unpack() : null),
    this.colorWidth(),
    this.colorHeight(),
    this.depthWidth(),
    this.depthHeight(),
    this.flags(),
    this.sessionIndex(),
    this.trackIndex(),
    (this.intrinsics() !== null ? this.intrinsics()!.unpack() : null),
    this.cameraGrainIntensity(),
    this.exposureDuration(),
    this.exposureOffset(),
    this.ambientIntensity(),
    this.ambientColorTemp(),
    this.brightness(),
    this.shutterSpeed(),
    this.aperture(),
    this.fNumber(),
    this.focalLength(),
    this.focalLengthIn35(),
    (this.optimizedXform() !== null ? this.optimizedXform()!.unpack() : null),
    this.frameIndex(),
    (this.linearVelocity() !== null ? this.linearVelocity()!.unpack() : null),
    (this.angularVelocity() !== null ? this.angularVelocity()!.unpack() : null),
    this.startFeature(),
    this.numFeatures(),
    this.startFragment(),
    this.numFragments()
  );
}


unpackTo(_o: FrameT): void {
  _o.timestampUs = this.timestampUs();
  _o.camera = (this.camera() !== null ? this.camera()!.unpack() : null);
  _o.colorWidth = this.colorWidth();
  _o.colorHeight = this.colorHeight();
  _o.depthWidth = this.depthWidth();
  _o.depthHeight = this.depthHeight();
  _o.flags = this.flags();
  _o.sessionIndex = this.sessionIndex();
  _o.trackIndex = this.trackIndex();
  _o.intrinsics = (this.intrinsics() !== null ? this.intrinsics()!.unpack() : null);
  _o.cameraGrainIntensity = this.cameraGrainIntensity();
  _o.exposureDuration = this.exposureDuration();
  _o.exposureOffset = this.exposureOffset();
  _o.ambientIntensity = this.ambientIntensity();
  _o.ambientColorTemp = this.ambientColorTemp();
  _o.brightness = this.brightness();
  _o.shutterSpeed = this.shutterSpeed();
  _o.aperture = this.aperture();
  _o.fNumber = this.fNumber();
  _o.focalLength = this.focalLength();
  _o.focalLengthIn35 = this.focalLengthIn35();
  _o.optimizedXform = (this.optimizedXform() !== null ? this.optimizedXform()!.unpack() : null);
  _o.frameIndex = this.frameIndex();
  _o.linearVelocity = (this.linearVelocity() !== null ? this.linearVelocity()!.unpack() : null);
  _o.angularVelocity = (this.angularVelocity() !== null ? this.angularVelocity()!.unpack() : null);
  _o.startFeature = this.startFeature();
  _o.numFeatures = this.numFeatures();
  _o.startFragment = this.startFragment();
  _o.numFragments = this.numFragments();
}
}

export class FrameT implements flatbuffers.IGeneratedObject {
constructor(
  public timestampUs: number = 0,
  public camera: CameraT|null = null,
  public colorWidth: number = 0,
  public colorHeight: number = 0,
  public depthWidth: number = 0,
  public depthHeight: number = 0,
  public flags: number = 0,
  public sessionIndex: number = 0,
  public trackIndex: number = 0,
  public intrinsics: Matrix3x3T|null = null,
  public cameraGrainIntensity: number = 0.0,
  public exposureDuration: number = 0.0,
  public exposureOffset: number = 0.0,
  public ambientIntensity: number = 0.0,
  public ambientColorTemp: number = 0.0,
  public brightness: number = 0.0,
  public shutterSpeed: number = 0.0,
  public aperture: number = 0.0,
  public fNumber: number = 0.0,
  public focalLength: number = 0.0,
  public focalLengthIn35: number = 0.0,
  public optimizedXform: TRXform2D5T|null = null,
  public frameIndex: number = -1,
  public linearVelocity: Vector3T|null = null,
  public angularVelocity: Vector3T|null = null,
  public startFeature: number = 0,
  public numFeatures: number = 0,
  public startFragment: number = 0,
  public numFragments: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const camera = (this.camera !== null ? this.camera!.pack(builder) : 0);

  Frame.startFrame(builder);
  Frame.addTimestampUs(builder, this.timestampUs);
  Frame.addCamera(builder, camera);
  Frame.addColorWidth(builder, this.colorWidth);
  Frame.addColorHeight(builder, this.colorHeight);
  Frame.addDepthWidth(builder, this.depthWidth);
  Frame.addDepthHeight(builder, this.depthHeight);
  Frame.addFlags(builder, this.flags);
  Frame.addSessionIndex(builder, this.sessionIndex);
  Frame.addTrackIndex(builder, this.trackIndex);
  Frame.addIntrinsics(builder, (this.intrinsics !== null ? this.intrinsics!.pack(builder) : 0));
  Frame.addCameraGrainIntensity(builder, this.cameraGrainIntensity);
  Frame.addExposureDuration(builder, this.exposureDuration);
  Frame.addExposureOffset(builder, this.exposureOffset);
  Frame.addAmbientIntensity(builder, this.ambientIntensity);
  Frame.addAmbientColorTemp(builder, this.ambientColorTemp);
  Frame.addBrightness(builder, this.brightness);
  Frame.addShutterSpeed(builder, this.shutterSpeed);
  Frame.addAperture(builder, this.aperture);
  Frame.addFNumber(builder, this.fNumber);
  Frame.addFocalLength(builder, this.focalLength);
  Frame.addFocalLengthIn35(builder, this.focalLengthIn35);
  Frame.addOptimizedXform(builder, (this.optimizedXform !== null ? this.optimizedXform!.pack(builder) : 0));
  Frame.addFrameIndex(builder, this.frameIndex);
  Frame.addLinearVelocity(builder, (this.linearVelocity !== null ? this.linearVelocity!.pack(builder) : 0));
  Frame.addAngularVelocity(builder, (this.angularVelocity !== null ? this.angularVelocity!.pack(builder) : 0));
  Frame.addStartFeature(builder, this.startFeature);
  Frame.addNumFeatures(builder, this.numFeatures);
  Frame.addStartFragment(builder, this.startFragment);
  Frame.addNumFragments(builder, this.numFragments);

  return Frame.endFrame(builder);
}
}
