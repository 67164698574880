import { ToCpp, ToCppT } from './flatbuffers/fbfloorplanner/to-cpp'
import { FromCpp, FromCppT } from './flatbuffers/fbfloorplanner/from-cpp'
import { Scan, ScanT } from './flatbuffers/fbfloorplanner/scan'
import { SurfaceAction } from './flatbuffers/fbfloorplanner/surface-action'
import { SurfaceMessageT } from './flatbuffers/fbfloorplanner/surface-message'
import { ViewportChangeT } from './flatbuffers/fbfloorplanner/viewport-change'
import { Viewport } from './flatbuffers/fbfloorplanner/viewport'
import { Perspective } from './flatbuffers/fbfloorplanner/perspective'
import { IncomingMessage } from './flatbuffers/fbfloorplanner/incoming-message'
import { OutgoingMessage } from './flatbuffers/fbfloorplanner/outgoing-message'
import { NewAnnotationT } from './flatbuffers/fbfloorplanner/new-annotation'
import { NewMeasurementT } from './flatbuffers/fbfloorplanner/new-measurement'
import * as flatbuffers from 'flatbuffers';
import { Modal, Tooltip, Toast } from 'bootstrap'
import { API as Slider, create} from 'nouislider';

import { transition, isLog, on, isMobile } from './_utils'
import { fbfloorplanner } from './flatbuffers/alignment'

import { Annotations,AnnotationsT } from './flatbuffers/fbfloorplanner/annotations'
import { Model,ModelT } from './flatbuffers/fbfloorplanner/model'
//import { PointCloud,PointCloudT } from './flatbuffers/fbfloorplanner/pointcloud'
import { ScanStructure } from './flatbuffers/fbfloorplanner/scan-structure'
import { Splats } from './flatbuffers/fbfloorplanner/splats'
import { PointCloud } from './flatbuffers/fbfloorplanner/point-cloud'
import { DecodedFlatbufferType } from './flatbuffers/fbfloorplanner/decoded-flatbuffer-type'
import { DecodedFlatbufferT } from './flatbuffers/fbfloorplanner/decoded-flatbuffer'
import { firebaseStorage } from './firebase/_storage'
import { user, addUserChangeHandler } from './firebase/_auth'
import { ref, uploadBytes, getBytes } from 'firebase/storage'
import { User } from 'firebase/auth'

// A texture waiting for be loaded.
export type PendingTexture = {
    atlasIndex: number;
    webP: ArrayBuffer;
};

// These must match the flags in modelview.hpp
export enum Flags {
    kIsPositionSnappinEnabled    = 1 << 1,
    kIsOrientationSnappinEnabled = 1 << 2,
    kSnapToWalls                 = 1 << 29,
    kIsMetric                    = 1 << 3,
    kDetectPlanes                = 1 << 4,
    kShowModelFront              = 1 << 5,

    kDemoMode = 1 << 7,

    kRotationLocked    = 1 << 8,
    kTranslationLocked = 1 << 9,

    kShowModel  = 1 << 10,
    kShowSplats = 1 << 11,
    kShowPointCloud = 1 << 12,

    kCropping = 1 << 13,
    kSlicing  = 1 << 14,
    kFraming  = 1 << 15,

    kAnnotating        = 1 << 16,
    kRotating          = 1 << 17,
    kMeasuring         = 1 << 18,
    kRecting           = 1 << 19,
    kRaycasting        = 1 << 25,
    kMeasurementAdding = 1 << 26,
    kMeasurementLoop   = 1 << 10,

    kShowStructure = 1 << 20,

    kSlicingTop    = 1 << 21,
    kSlicingBottom = 1 << 22,

    kHighlightMeasurements = 1 << 23,
    kHighlightAnnotations  = 1 << 27,
    kShowingSlice          = 1 << 24,

    kShowWireframe = 1 << 28,

    kCropInside = 1 << 30,
}

export enum OutFlags {
    kNone = 0,
    kIsHovering             = 1 << 1,
    kFocussedHorizontally   = 1 << 2,
    kFocussedVertically     = 1 << 3,
    kAnnotationsEmpty       = 1 << 4,
    kMeasurementPointsEmpty = 1 << 5,
}


// The current user.
export let viewer : Viewer | null = null;

// Create a type for the user change handler
export type ViewerChanged = (viewer: Viewer | null) => void;

// Initialize an array to store multiple handlers
const viewerChangeHandlers: ViewerChanged[] = [];

// Export function to add handlers
export function addViewerChangeHandler(handler: ViewerChanged) {
    viewerChangeHandlers.push(handler);
}

// Export function to remove handlers
export function removeViewerChangeHandler(handler: ViewerChanged) {
    const index = viewerChangeHandlers.indexOf(handler);
    if (index > -1) {
        viewerChangeHandlers.splice(index, 1);
    }
}


export class Viewer {
    public scanId: string;
    public nativeViewer: NativeViewer;
    public _flags: Flags = Flags.kIsMetric | Flags.kShowModel;
    public outFlags: OutFlags = OutFlags.kNone;
    public annotations: AnnotationsT = new AnnotationsT();
    public isMetric: boolean = true;
    public bottom: number = 0.0;
    public hoverId: number = -1;
    public top: number = 1.0;
    public perspective: boolean = true;
    public levelSlider: Slider = create(document.getElementById('levelSelectorSlider') as HTMLElement, {
        start: [0, 100],
        connect: true,
        orientation: 'vertical',
        direction: 'rtl',
        range: {
            'min': 0,
            'max': 100
        }
    });
    public cameraSnapPosition: string = 'freePosition';
    public cameraSnapRotation: string = 'freeRotation';
    public cameraSnapWhat: string = 'snapToWalls';

    constructor(scanId: string, nativeViewer: NativeViewer, pendingBytesArray: ArrayBuffer[], pendingTextures: PendingTexture[]) {
        this.scanId = scanId;
        this.nativeViewer = nativeViewer;

        window.addEventListener('wheel', function(event) {
            if (event.deltaX !== 0) {
                event.preventDefault(); // Prevent horizontal scrolling
            }
        }, { passive: false });

        // Do we have locally saved annotations?
        const annotations = localStorage.getItem(`annotations-${this.scanId}`);
        if (annotations) {
            this.dispatchFlatbuffer(new Uint8Array(JSON.parse(annotations)));
        }

        // Initialize the levels slider.
        this.levelSlider.on('update', () => {
            // Get the values.  
            var values = this.levelSlider.get() as number[];
            var bottom = parseFloat(values[0].toString());
            var top = parseFloat(values[1].toString());
            if (bottom < top) {
                this.nativeViewer.setVerticalZRange(bottom, top);
            }
        });

        document.querySelectorAll('.metricToggle').forEach(button => {
            (button as HTMLButtonElement).onclick = () => {
                this.setFlags(this._flags ^ Flags.kIsMetric);
                this.updateHoverMetrics();

                if ((this._flags & Flags.kIsMetric) == Flags.kIsMetric) {
                    Toast.getInstance(document.getElementById('imperialUnitsToast') as HTMLElement)?.hide();
                    Toast.getOrCreateInstance(document.getElementById('metricUnitsToast') as HTMLElement).show();
                } else {
                    Toast.getInstance(document.getElementById('metricUnitsToast') as HTMLElement)?.hide();
                    Toast.getOrCreateInstance(document.getElementById('imperialUnitsToast') as HTMLElement)?.show();
                }
            }
        });

        
        (document.getElementById('hoverDeleteButton') as HTMLButtonElement).onclick = () => {
            if (this._flags & Flags.kAnnotating) {
                this.annotations.annotations.splice(this.hoverId, 1);
            } else if (this._flags & Flags.kMeasuring) {
                this.annotations.measurements.splice(this.hoverId, 1);
            }

            this.updatedAnnotations();
        }


        // Initialize the tooltips.
        if (!isMobile.any()) {
            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new Tooltip(tooltipTriggerEl)
            })
        }

        // Synchronize the initial flags.   
        this.setFlags(this._flags);

        // Process and clear arrays as we go
        while (pendingBytesArray.length > 0) {
            const bytes = pendingBytesArray.pop();

            if (bytes) this.dispatchFlatbuffer(bytes);
        }
        
        while (pendingTextures.length > 0) {
            const texture = pendingTextures.pop();
            if (texture) {
                this.nativeViewer.setWebPForAtlas(texture.atlasIndex, texture.webP);
            }
        }

        (document.getElementById('cameraSnapPositionSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.cameraSnapPosition = (event.target as HTMLSelectElement).value;
            this.syncCameraSnapFlags();
        }

        (document.getElementById('cameraSnapRotationSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.cameraSnapRotation = (event.target as HTMLSelectElement).value;
            this.syncCameraSnapFlags();
        }

        (document.getElementById('cameraSnapTargetSelect') as HTMLSelectElement).onchange = (event) => {
            event.preventDefault();
            this.cameraSnapWhat = (event.target as HTMLSelectElement).value;
            this.syncCameraSnapFlags();
        }
    }

    async downloadAnnotationsForUser(user: User) {
        try {
            const bytes = await getBytes(ref(firebaseStorage, `users/${user.uid}/annotations/${this.scanId}/annotations.fb`));
            this.dispatchFlatbuffer(bytes);
        } catch (error) {
            isLog("No user annotations");
        }
        
    }

    public onHoverChanged(newId: number) {
        isLog(`Hover ID changed to ${newId}`);
        this.hoverId = newId;
        this.updateHoverMetrics();
    }

    public updateHoverMetrics() {
        if (this.hoverId == -1) {
            document.getElementById('hoverBar')?.classList.add('d-none');
        } else {
            document.getElementById('hoverBar')?.classList.remove('d-none');

            (document.getElementById('hoverTitle') as HTMLSpanElement).innerHTML = '<i class="bi bi-dot"></i>' + this.nativeViewer.metricsFor(false, (this._flags & Flags.kAnnotating) != 0, this.hoverId);
        }
    }

    public onOutFlagsChanged(newFlags: number) {
        isLog(`Flags changed to ${newFlags}`);

        if ((this.outFlags ^ newFlags) & OutFlags.kIsHovering) {
        }

        if ((this.outFlags ^ newFlags) & OutFlags.kAnnotationsEmpty) {
            document.querySelectorAll('.needsSelection').forEach(button => {
                if (newFlags & OutFlags.kAnnotationsEmpty) {
                    (button as HTMLButtonElement).disabled = true;
                } else {
                    (button as HTMLButtonElement).disabled = false;
                }
            });
        }

        if ((this.outFlags ^ newFlags) & OutFlags.kFocussedHorizontally) {
            document.querySelectorAll('.needsHorizontalFocus').forEach(item => {
                if (newFlags & OutFlags.kFocussedHorizontally) {
                    item.classList.remove('d-none');
                } else {
                    item.classList.add('d-none');
                }
            });
        }

        if ((this.outFlags ^ newFlags) & OutFlags.kMeasurementPointsEmpty) {
            document.querySelectorAll('.needsMeasurementPoints').forEach(button => {
                if (newFlags & OutFlags.kMeasurementPointsEmpty) {
                    (button as HTMLButtonElement).disabled = true;
                } else {
                    (button as HTMLButtonElement).disabled = false;
                }
            });
        }

        this.outFlags = newFlags;
    }

    public syncCameraSnapFlags() {
        var flags = this._flags & ~(Flags.kIsPositionSnappinEnabled | Flags.kIsOrientationSnappinEnabled | Flags.kRotationLocked | Flags.kTranslationLocked | Flags.kSnapToWalls);

        if (this.cameraSnapPosition == 'freePosition') {
            // Nothing to do, default behavior....
        } else if (this.cameraSnapPosition == 'snappedPosition') {
            flags |= Flags.kIsPositionSnappinEnabled;
        } else {
            console.error('Invalid camera snap position: ', this.cameraSnapPosition);
        }

        if (this.cameraSnapRotation == 'freeRotation') {
            // Nothing to do, default behavior....
        } else if (this.cameraSnapRotation == 'lockedRotation') {
            flags |= Flags.kRotationLocked;
        } else if (this.cameraSnapRotation == 'snappedRotation') {
            flags |= Flags.kIsOrientationSnappinEnabled;
        } else {
            console.error('Invalid camera snap rotation: ', this.cameraSnapRotation);
        }

        if (this.cameraSnapWhat == 'snapToWalls') {
            flags |= Flags.kSnapToWalls;
        } else if (this.cameraSnapWhat == 'snapToAnnotations') {
            // Nothing to do, default behavior....
        } else {
            console.error('Invalid camera snap type: ', this.cameraSnapWhat);
        }

        if (flags != this._flags) {
            this.setFlags(flags);
        }
    }

    public setFlags(flags: Flags) {
        this.nativeViewer.setFlags(flags);
    
        if ((this._flags ^ flags) & Flags.kMeasuring) {
    
            if (flags & Flags.kMeasuring) {
                transition('scanMainButton', 'measurementMain');
            } else {
                transition('measurementMain', 'scanMainButton');
            }
        }
    
        if ((this._flags ^ flags) & Flags.kAnnotating) {
            if (flags & Flags.kAnnotating) {
                transition('scanMainButton', 'annotationsMain');
            } else {
                transition('annotationsMain', 'scanMainButton');
            }
        }
    
        if ((this._flags ^ flags) & Flags.kSlicing) {
            if (flags & Flags.kSlicing) {
                transition('scanMainButton', 'levelSelector');
            } else {
                transition('levelSelector', 'scanMainButton');
            }
        }
    
        this._flags = flags;
    }

    public formatLength(length: number) {
        if (this.isMetric) {
            return length.toFixed(2) + ' m';
        } else {
            return length.toFixed(2) + ' ft';
        }
    }

    public formatArea(area: number) {
        if (this.isMetric) {
            return area.toFixed(2) + ' m²';
        } else {
            return area.toFixed(2) + ' ft²';
        }
    }

    public setVerticalRange(bottom: number, top: number) {
    }

    // Check the flatbuffer before we dispatch to the native code.
    private checkFlatbuffer(bytes: ArrayBuffer) {
        var bb = new flatbuffers.ByteBuffer(new Uint8Array(bytes));

        if (Model.bufferHasIdentifier(bb)) {
            const vol = Model.getRootAsModel(bb).volume();
            if (vol) {
                this.bottom = (vol.xform()?.translation()?.z() ?? 0) + (vol.bounds()?.min()?.z() ?? 0);
                this.top = (vol.xform()?.translation()?.z() ?? 0) + (vol.bounds()?.max()?.z() ?? 0);

                this.levelSlider.updateOptions({
                    range: {
                        'min': this.bottom,
                        'max': this.top
                    }
                }, false);

                this.levelSlider.set([this.bottom, this.top]);
            } else {
                console.error("Model has no volume");
                this.bottom = 0;
                this.top = 0;
            }
        } else if (Annotations.bufferHasIdentifier(bb)) {
            this.annotations = Annotations.getRootAsAnnotations(bb).unpack();
            isLog("Annotations loaded");
        } else if (ScanStructure.bufferHasIdentifier(bb)) {
            isLog("Dispatching scan structure");
        } else if (PointCloud.bufferHasIdentifier(bb)) {
            isLog("Dispatching point cloud");
        } else if (Splats.bufferHasIdentifier(bb)) {
            isLog("Dispatching splats");
        } else if (ToCpp.bufferHasIdentifier(bb)) {
            isLog("Dispatching toCpp");
        } else if (Scan.bufferHasIdentifier(bb)) {
            isLog("Dispatching toCpp");
        } else {
            isLog("Unknown identifier to C++:", bb.getBufferIdentifier().toString());
        }
    }

    // Check the response from the native code.
    private checkResponse(fromCpp: FromCppT) : FromCppT {
        switch (fromCpp.messageType) {
            case OutgoingMessage.NONE:
                break;
            case OutgoingMessage.NewAnnotation:
                // The Caller will handle.
                break;
            case OutgoingMessage.NewMeasurement:
                // The Caller will handle.
                break;
            default:
                break;
        }

        return fromCpp;
    }

    dispatchFlatbuffer(bytes: ArrayBuffer) : FromCppT {
        this.checkFlatbuffer(bytes);

        var bb = new flatbuffers.ByteBuffer(this.nativeViewer.setImageSpaceData(new Uint8Array(bytes)));
        if (FromCpp.bufferHasIdentifier(bb)) {
            return FromCpp.getRootAsFromCpp(bb).unpack();
        } else {
            console.error("Invalid buffer identifier: " + bb.getBufferIdentifier());
            return new FromCppT();
        }
    }

    dispatch(message: ToCppT) : FromCppT {
        var builder = new flatbuffers.Builder(1024);
        ToCpp.finishToCppBuffer(builder, message.pack(builder));
        return this.checkResponse(this.dispatchFlatbuffer(builder.asUint8Array()));
    }

    setWebPForAtlas(atlasIndex: number, webpData: ArrayBuffer) {
        this.nativeViewer.setWebPForAtlas(atlasIndex, webpData);
    }


    async updatedAnnotations() : Promise<FromCppT> {

        // Serialize the annotations and dispatch to the native view.
        var builder = new flatbuffers.Builder(1024);
        Annotations.finishAnnotationsBuffer(builder, this.annotations.pack(builder));

        // Grab the underlying bytes.
        var bytes = builder.asUint8Array();

        // Store locally.
        localStorage.setItem(`annotations-${this.scanId}`, JSON.stringify(Array.from(bytes)));

        // Overwrite the existing annotations.
        if ((user != null) && (user.isAnonymous == false)) {
            await uploadBytes(ref(firebaseStorage, `users/${user.uid}/annotations/${this.scanId}/annotations.fb`), bytes).then(() => {
                // Nothing to do here.
            }).catch(() => {
                // It's okay if this fails.
            });

        } else {
            Toast.getOrCreateInstance(document.getElementById('notSavedToast') as HTMLElement).show();
        }

        return this.checkResponse(this.dispatchFlatbuffer(bytes));
    }
}