// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum RoomType {
  Other = 0,
  LivingRoom = 1,
  DiningRoom = 2,
  Kitchen = 3,
  Bedroom = 4,
  Bathroom = 5,
  Office = 6,
  Pantry = 7,
  Hallway = 8,
  Closet = 9,
  Garage = 10
}
