// ImageSpace main entry point.
import { firebaseApp } from './firebase/_app';
import { firebaseDb } from './firebase/_database';
import { setupAuth } from './firebase/_auth';
import { doc, setDoc} from 'firebase/firestore';
import { Tooltip, Toast, Popover, Carousel, Modal } from 'bootstrap';
import { transition } from './_utils';
import { setupViewer } from './_viewer';

// Setup the authentication.
//setupAuth();

document.addEventListener('DOMContentLoaded', function () {
    // Select all rows you want to observe
    const sections = document.querySelectorAll('.row');
    
    // Create an IntersectionObserver instance
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('highlight');
            } else {
                entry.target.classList.remove('highlight');
            }
        });
    }, { threshold: 0.5 });

    // Observe each section
    sections.forEach(section => {
        observer.observe(section);
    });
});

// // Setup the viewer.
setupViewer();

//console.log("Ready!");


