// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Volume, VolumeT } from '../fbfloorplanner/volume.js';


export class Splats implements flatbuffers.IUnpackableObject<SplatsT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Splats {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSplats(bb:flatbuffers.ByteBuffer, obj?:Splats):Splats {
  return (obj || new Splats()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSplats(bb:flatbuffers.ByteBuffer, obj?:Splats):Splats {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Splats()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static bufferHasIdentifier(bb:flatbuffers.ByteBuffer):boolean {
  return bb.__has_identifier('SPLT');
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

volume(obj?:Volume):Volume|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Volume()).__init(this.bb_pos + offset, this.bb!) : null;
}

gaussians():string|null
gaussians(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
gaussians(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startSplats(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(0, version, 0);
}

static addVolume(builder:flatbuffers.Builder, volumeOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, volumeOffset, 0);
}

static addGaussians(builder:flatbuffers.Builder, gaussiansOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, gaussiansOffset, 0);
}

static endSplats(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishSplatsBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'SPLT');
}

static finishSizePrefixedSplatsBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'SPLT', true);
}


unpack(): SplatsT {
  return new SplatsT(
    this.version(),
    (this.volume() !== null ? this.volume()!.unpack() : null),
    this.gaussians()
  );
}


unpackTo(_o: SplatsT): void {
  _o.version = this.version();
  _o.volume = (this.volume() !== null ? this.volume()!.unpack() : null);
  _o.gaussians = this.gaussians();
}
}

export class SplatsT implements flatbuffers.IGeneratedObject {
constructor(
  public version: number = 0,
  public volume: VolumeT|null = null,
  public gaussians: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const gaussians = (this.gaussians !== null ? builder.createString(this.gaussians!) : 0);

  Splats.startSplats(builder);
  Splats.addVersion(builder, this.version);
  Splats.addVolume(builder, (this.volume !== null ? this.volume!.pack(builder) : 0));
  Splats.addGaussians(builder, gaussians);

  return Splats.endSplats(builder);
}
}
