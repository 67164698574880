// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Perspective } from '../fbfloorplanner/perspective.js';
import { Viewport } from '../fbfloorplanner/viewport.js';


export class ViewportChange implements flatbuffers.IUnpackableObject<ViewportChangeT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ViewportChange {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsViewportChange(bb:flatbuffers.ByteBuffer, obj?:ViewportChange):ViewportChange {
  return (obj || new ViewportChange()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsViewportChange(bb:flatbuffers.ByteBuffer, obj?:ViewportChange):ViewportChange {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ViewportChange()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

viewport():Viewport {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : Viewport.TopDown;
}

perspective():Perspective {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : Perspective.Perspective;
}

animated():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startViewportChange(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addViewport(builder:flatbuffers.Builder, viewport:Viewport) {
  builder.addFieldInt8(0, viewport, Viewport.TopDown);
}

static addPerspective(builder:flatbuffers.Builder, perspective:Perspective) {
  builder.addFieldInt8(1, perspective, Perspective.Perspective);
}

static addAnimated(builder:flatbuffers.Builder, animated:boolean) {
  builder.addFieldInt8(2, +animated, +false);
}

static endViewportChange(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createViewportChange(builder:flatbuffers.Builder, viewport:Viewport, perspective:Perspective, animated:boolean):flatbuffers.Offset {
  ViewportChange.startViewportChange(builder);
  ViewportChange.addViewport(builder, viewport);
  ViewportChange.addPerspective(builder, perspective);
  ViewportChange.addAnimated(builder, animated);
  return ViewportChange.endViewportChange(builder);
}

unpack(): ViewportChangeT {
  return new ViewportChangeT(
    this.viewport(),
    this.perspective(),
    this.animated()
  );
}


unpackTo(_o: ViewportChangeT): void {
  _o.viewport = this.viewport();
  _o.perspective = this.perspective();
  _o.animated = this.animated();
}
}

export class ViewportChangeT implements flatbuffers.IGeneratedObject {
constructor(
  public viewport: Viewport = Viewport.TopDown,
  public perspective: Perspective = Perspective.Perspective,
  public animated: boolean = false
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return ViewportChange.createViewportChange(builder,
    this.viewport,
    this.perspective,
    this.animated
  );
}
}
