// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { AnnotationMessage, AnnotationMessageT } from '../fbfloorplanner/annotation-message.js';
import { HighlightAnnotation, HighlightAnnotationT } from '../fbfloorplanner/highlight-annotation.js';
import { HighlightFrame, HighlightFrameT } from '../fbfloorplanner/highlight-frame.js';
import { MeasurementMessage, MeasurementMessageT } from '../fbfloorplanner/measurement-message.js';
import { SurfaceMessage, SurfaceMessageT } from '../fbfloorplanner/surface-message.js';
import { ViewportChange, ViewportChangeT } from '../fbfloorplanner/viewport-change.js';


export enum IncomingMessage {
  NONE = 0,
  SurfaceMessage = 1,
  AnnotationMessage = 2,
  MeasurementMessage = 3,
  HighlightFrame = 4,
  HighlightAnnotation = 5,
  ViewportChange = 6
}

export function unionToIncomingMessage(
  type: IncomingMessage,
  accessor: (obj:AnnotationMessage|HighlightAnnotation|HighlightFrame|MeasurementMessage|SurfaceMessage|ViewportChange) => AnnotationMessage|HighlightAnnotation|HighlightFrame|MeasurementMessage|SurfaceMessage|ViewportChange|null
): AnnotationMessage|HighlightAnnotation|HighlightFrame|MeasurementMessage|SurfaceMessage|ViewportChange|null {
  switch(IncomingMessage[type]) {
    case 'NONE': return null; 
    case 'SurfaceMessage': return accessor(new SurfaceMessage())! as SurfaceMessage;
    case 'AnnotationMessage': return accessor(new AnnotationMessage())! as AnnotationMessage;
    case 'MeasurementMessage': return accessor(new MeasurementMessage())! as MeasurementMessage;
    case 'HighlightFrame': return accessor(new HighlightFrame())! as HighlightFrame;
    case 'HighlightAnnotation': return accessor(new HighlightAnnotation())! as HighlightAnnotation;
    case 'ViewportChange': return accessor(new ViewportChange())! as ViewportChange;
    default: return null;
  }
}

export function unionListToIncomingMessage(
  type: IncomingMessage, 
  accessor: (index: number, obj:AnnotationMessage|HighlightAnnotation|HighlightFrame|MeasurementMessage|SurfaceMessage|ViewportChange) => AnnotationMessage|HighlightAnnotation|HighlightFrame|MeasurementMessage|SurfaceMessage|ViewportChange|null, 
  index: number
): AnnotationMessage|HighlightAnnotation|HighlightFrame|MeasurementMessage|SurfaceMessage|ViewportChange|null {
  switch(IncomingMessage[type]) {
    case 'NONE': return null; 
    case 'SurfaceMessage': return accessor(index, new SurfaceMessage())! as SurfaceMessage;
    case 'AnnotationMessage': return accessor(index, new AnnotationMessage())! as AnnotationMessage;
    case 'MeasurementMessage': return accessor(index, new MeasurementMessage())! as MeasurementMessage;
    case 'HighlightFrame': return accessor(index, new HighlightFrame())! as HighlightFrame;
    case 'HighlightAnnotation': return accessor(index, new HighlightAnnotation())! as HighlightAnnotation;
    case 'ViewportChange': return accessor(index, new ViewportChange())! as ViewportChange;
    default: return null;
  }
}
