// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum DecodedFlatbufferType {
  Unknown = 0,
  DecodedModel = 1,
  DecodedAnnotations = 2,
  DecodedStructure = 3,
  DecodedToCpp = 4
}
