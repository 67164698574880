import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDgoFSPNF17tLYAuYVDueRwiHL1LO9W9OU",
    authDomain: "imagespace-4f2f8.firebaseapp.com",
    projectId: "imagespace-4f2f8",
    storageBucket: "imagespace-4f2f8.appspot.com",
    messagingSenderId: "216971791970",
    appId: "1:216971791970:web:1847f5f1c16e753a350b3b"
  };
  
// Initialize Firebase.
export const firebaseApp = initializeApp(firebaseConfig);
    