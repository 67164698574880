// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';



export class Level implements flatbuffers.IUnpackableObject<LevelT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Level {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsLevel(bb:flatbuffers.ByteBuffer, obj?:Level):Level {
  return (obj || new Level()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsLevel(bb:flatbuffers.ByteBuffer, obj?:Level):Level {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Level()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

title():string|null
title(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
title(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

top():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

bottom():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

height():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

aboveGrade():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : true;
}

exportable():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : true;
}

static startLevel(builder:flatbuffers.Builder) {
  builder.startObject(6);
}

static addTitle(builder:flatbuffers.Builder, titleOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, titleOffset, 0);
}

static addTop(builder:flatbuffers.Builder, top:number) {
  builder.addFieldFloat32(1, top, 0.0);
}

static addBottom(builder:flatbuffers.Builder, bottom:number) {
  builder.addFieldFloat32(2, bottom, 0.0);
}

static addHeight(builder:flatbuffers.Builder, height:number) {
  builder.addFieldFloat32(3, height, 0.0);
}

static addAboveGrade(builder:flatbuffers.Builder, aboveGrade:boolean) {
  builder.addFieldInt8(4, +aboveGrade, +true);
}

static addExportable(builder:flatbuffers.Builder, exportable:boolean) {
  builder.addFieldInt8(5, +exportable, +true);
}

static endLevel(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createLevel(builder:flatbuffers.Builder, titleOffset:flatbuffers.Offset, top:number, bottom:number, height:number, aboveGrade:boolean, exportable:boolean):flatbuffers.Offset {
  Level.startLevel(builder);
  Level.addTitle(builder, titleOffset);
  Level.addTop(builder, top);
  Level.addBottom(builder, bottom);
  Level.addHeight(builder, height);
  Level.addAboveGrade(builder, aboveGrade);
  Level.addExportable(builder, exportable);
  return Level.endLevel(builder);
}

unpack(): LevelT {
  return new LevelT(
    this.title(),
    this.top(),
    this.bottom(),
    this.height(),
    this.aboveGrade(),
    this.exportable()
  );
}


unpackTo(_o: LevelT): void {
  _o.title = this.title();
  _o.top = this.top();
  _o.bottom = this.bottom();
  _o.height = this.height();
  _o.aboveGrade = this.aboveGrade();
  _o.exportable = this.exportable();
}
}

export class LevelT implements flatbuffers.IGeneratedObject {
constructor(
  public title: string|Uint8Array|null = null,
  public top: number = 0.0,
  public bottom: number = 0.0,
  public height: number = 0.0,
  public aboveGrade: boolean = true,
  public exportable: boolean = true
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const title = (this.title !== null ? builder.createString(this.title!) : 0);

  return Level.createLevel(builder,
    title,
    this.top,
    this.bottom,
    this.height,
    this.aboveGrade,
    this.exportable
  );
}
}
