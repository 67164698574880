// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { AnnotationSemantics, AnnotationSemanticsT } from '../fbfloorplanner/annotation-semantics.js';
import { ModelSlice, ModelSliceT } from '../fbfloorplanner/model-slice.js';


export class Annotation implements flatbuffers.IUnpackableObject<AnnotationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Annotation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsAnnotation(bb:flatbuffers.ByteBuffer, obj?:Annotation):Annotation {
  return (obj || new Annotation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsAnnotation(bb:flatbuffers.ByteBuffer, obj?:Annotation):Annotation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Annotation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

semantics(obj?:AnnotationSemantics):AnnotationSemantics|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new AnnotationSemantics()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

slices(index: number, obj?:ModelSlice):ModelSlice|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ModelSlice()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

slicesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startAnnotation(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addSemantics(builder:flatbuffers.Builder, semanticsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, semanticsOffset, 0);
}

static addSlices(builder:flatbuffers.Builder, slicesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, slicesOffset, 0);
}

static createSlicesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSlicesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endAnnotation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createAnnotation(builder:flatbuffers.Builder, semanticsOffset:flatbuffers.Offset, slicesOffset:flatbuffers.Offset):flatbuffers.Offset {
  Annotation.startAnnotation(builder);
  Annotation.addSemantics(builder, semanticsOffset);
  Annotation.addSlices(builder, slicesOffset);
  return Annotation.endAnnotation(builder);
}

unpack(): AnnotationT {
  return new AnnotationT(
    (this.semantics() !== null ? this.semantics()!.unpack() : null),
    this.bb!.createObjList<ModelSlice, ModelSliceT>(this.slices.bind(this), this.slicesLength())
  );
}


unpackTo(_o: AnnotationT): void {
  _o.semantics = (this.semantics() !== null ? this.semantics()!.unpack() : null);
  _o.slices = this.bb!.createObjList<ModelSlice, ModelSliceT>(this.slices.bind(this), this.slicesLength());
}
}

export class AnnotationT implements flatbuffers.IGeneratedObject {
constructor(
  public semantics: AnnotationSemanticsT|null = null,
  public slices: (ModelSliceT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const semantics = (this.semantics !== null ? this.semantics!.pack(builder) : 0);
  const slices = Annotation.createSlicesVector(builder, builder.createObjectOffsetList(this.slices));

  return Annotation.createAnnotation(builder,
    semantics,
    slices
  );
}
}
