import { loadScan } from './_viewer'
import { loadSnapshotsFor } from './_snapshots'
import { loadSnapshot } from './_snapshot'

export function setupParameters() {

    // Parse the query string.
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const scan = urlParams.get('s');

    // Which html file are we in?
    const htmlFile = window.location.pathname;

    if (scan) {

        // If we are in the viewer, then we need to load the scan.
        if (htmlFile.endsWith('viewer.html')) {
            loadScan(scan);
        } else if (htmlFile.endsWith('snapshots.html')) {
            loadSnapshotsFor(scan);
        } else if (htmlFile.endsWith('snapshot.html')) {
            const snapshotId = urlParams.get('t');
            if (snapshotId) {
                loadSnapshot(scan, snapshotId);
            } else {
                // Redirect to 404
                window.location.href = "/404.html";
            }
        }
    }
}



